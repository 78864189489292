import { DataTypeEnum } from '@shared/enums';

export const ACCOUNT_INFO_DATA = [
  {
    name: 'owner',
    isLink: false,
    dataType: DataTypeEnum.Text,
  }, {
    name: 'website',
    isLink: true,
    dataType: DataTypeEnum.Text,
  },
  {
    name: 'source',
    isLink: false,
    dataType: DataTypeEnum.Text,
  }, {
    name: 'totalOpenOpportunityValue',
    isLink: false,
    dataType: DataTypeEnum.Currency,
  }, {
    name: 'totalClosedWonRevenue',
    isLink: false,
    dataType: DataTypeEnum.Currency,
  }
];
