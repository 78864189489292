import { INavMenu } from '@shared/interfaces';
import { NavPanelTypes, NavSectionTypes, RouteItemEnum } from '@shared/enums';
import { SPLIT_IO_KEYS } from '@shared/constants';

export const DATA_STUDIO_NAV: INavMenu = [
  {
    section: NavSectionTypes.Primary,
    items: [
      {
        icon: 'warehouse-alt',
        type: NavPanelTypes.Basic,
        label: 'routeLabels.accountHub',
        routeId: RouteItemEnum.AccountHub,
        routeItem: null,
      },
      {
        icon: 'chart-pie',
        type: NavPanelTypes.Basic,
        label: 'routeLabels.segmentIndex',
        routeId: RouteItemEnum.SegmentIndex,
        routeItem: null,
      }
    ],
  },
];
