import { createFeatureSelector, createSelector } from '@ngrx/store';
import { format, setISOWeek, setYear, startOfISOWeek } from 'date-fns';

import { accountSpecificFeatureKey, AccountSpecificState } from './account-specific.reducer';
import { DataTypeEnum, SortDirections } from '@shared/enums';
import { IAccountSpecificParams } from '../interfaces/account-params.interface';
import { IAccountOpportunity } from '../interfaces/account-opportunity.interface';
import { IAccountSalesData } from '../interfaces/account-sales-data.interface';
import { IAccountTouches } from '../interfaces/account-touches.interface';
import { IAccountChartData } from '../interfaces/account-chart.interface';
import { AccountSpecificColors } from '../data/account-specific-colors.data';
import { ILabelValue, IReportTable, ReportMetric } from '@shared/interfaces';
import { IAccountAttributionTab } from '../interfaces/account-tabs.interface';
import { IAccountTableData } from '../interfaces/account-table.interface';
import {
  anonymousMapper,
  calculateEngagementSpikeCounts,
  calculateIntentsCounts,
  chartOpptyHistoryMapper,
  marketingMapper,
  opptyHistoryMapper,
  opptyMapper,
  salesMapper,
  sortByDate
} from '../account-specific.helper';
import { IAccountPeople, IAccountPerson } from '../interfaces/account-people.interface';
import { ACCOUNT_SCORE_HIGH_MARK, ACCOUNT_SCORE_LOW_MARK } from '../data/account-scores.data';
import { applyPageToReportData, compare, searchInReportData, sortReportData } from '@util/helpers';
import { IAccountAnonymousWeb, IAccountCalulatedIntentTopic } from '../interfaces/account-anonymous_web.interface';
import { IAccountOpptyHistory } from '../interfaces/account-oppty-history.interface';
import { IAccountActivity } from '../interfaces/account-activity.interface';
import { ActivityType } from '../enums/activity-type.enum';
import { IAccountInfo } from '../interfaces/account-info.interface';
import { AccountAttributionTabs } from '../enums/account-attribution-tabs.enum';
import { IAccountAdvertising } from '../interfaces/account-advertising.interface';

export const selectAccountSpecificState = createFeatureSelector<AccountSpecificState>(accountSpecificFeatureKey);

export const getAccountSpecificLoading = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.isLoading);

export const getAccountSpecificFilters = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.filters);

export const getAccountSpecificAttributionTabs = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState): { [k in AccountAttributionTabs]: IAccountAttributionTab } => state.accountAttributionTabs);

export const getAccountSpecificTabs = createSelector(
  getAccountSpecificAttributionTabs,
  (accountAttributionTabs: { [k in AccountAttributionTabs]: IAccountAttributionTab }): IAccountAttributionTab[] => {
    return Object.keys(accountAttributionTabs).map((key: AccountAttributionTabs) => {
      return {
        id: key,
        name: accountAttributionTabs[key].name,
        count: accountAttributionTabs[key].count,
      }
  });
});

export const getAccountSpecificActiveTabId = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.accountAttributionActiveTab
  );

export const getAccountSpecificActiveTab = createSelector(
  getAccountSpecificAttributionTabs,
  getAccountSpecificActiveTabId,
  (accountAttributionTabs: { [k in AccountAttributionTabs]: IAccountAttributionTab },
    accountAttributionActiveTab: AccountAttributionTabs
  ): IAccountAttributionTab => {
    return {
      id: accountAttributionActiveTab,
      ...accountAttributionTabs[accountAttributionActiveTab]
    }
  });

export const getAccountSpecificActiveTabVisibleColumns = createSelector(
  getAccountSpecificActiveTab,
    (activeTab: IAccountAttributionTab) => {
    return activeTab.table.columns.filter(column => column.visible);
  });

export const getAccountSpecificTabSort = createSelector(
  getAccountSpecificActiveTab,
    (activeTab: IAccountAttributionTab) => activeTab.table.sortState);

export const getAccountSpecificTabPage = createSelector(
  getAccountSpecificActiveTab,
    (activeTab: IAccountAttributionTab) => activeTab.table.pagination.page);

export const getAccountSpecificOpportunities = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.accountOpportunities);

export const getAccountSpecificTouches = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.accountTouches);

export const getAccountSpecificSales = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.accountSalesData);

export const getAccountSpecificSelectedCohort = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.selectedDateCohort);

export const getAccountSpecificId = createSelector(
  getAccountSpecificFilters,
    (filters: IAccountSpecificParams) => filters?.accountId);

export const getAccountSpecificInfo = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.accountInfo);

export const getAccountSpecificOpptyHistory = createSelector(
  selectAccountSpecificState,
    (state: AccountSpecificState) => state.accountOpptyHistory || {});

export const getAccountTraidingInfo = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.accountTradingInfo);

export const getAccountSpecificAnonymousWeb = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.accountAnonymousWeb);

export const getAccountSelectedTab = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.selectedTab);

export const getAccountSpecificCalculatedAnonymousWeb = createSelector(
  getAccountSpecificAnonymousWeb,
  (anonymousWeb: IAccountAnonymousWeb) => anonymousWeb && calculateIntentsCounts(anonymousWeb)
);

export const getAccountSpecificMetrics = createSelector(
  getAccountSpecificTouches,
  getAccountSpecificSales,
  getAccountSpecificCalculatedAnonymousWeb,
  getAccountSpecificAnonymousWeb,
    (info: IAccountTouches,
      sales: IAccountSalesData,
      topics: IAccountCalulatedIntentTopic[],
      anonymousWeb: IAccountAnonymousWeb): ReportMetric[] => {
      return [
        {
          title: 'dataStudio.features.accountSpecific.metricOptions.sales',
          current: {
            value: sales?.tasks.length,
            dataType: DataTypeEnum.Number,
            color: AccountSpecificColors.Sales
          }
        }, {
          title: 'dataStudio.features.accountSpecific.metricOptions.marketing',
          current: {
            value: +info?.activityList.length,
            dataType: DataTypeEnum.Number,
            color: AccountSpecificColors.Marketing
          }
        }, {
          title: 'dataStudio.features.accountSpecific.metricOptions.intentSurge',
          current: {
            value: topics?.reduce((sum, item) => sum + item.counts, 0) || 0,
            dataType: DataTypeEnum.Number,
            color: AccountSpecificColors.IntentSurge
          }
        }, {
          title: 'dataStudio.features.accountSpecific.metricOptions.pageViews',
          current: {
            value: (anonymousWeb?.pageViews.reduce((sum, item) => sum + item.counts, 0) || 0),
            dataType: DataTypeEnum.Number,
            color: AccountSpecificColors.SiteEngagements
          }
        }
      ]
    });

export const  getAccountSpecificPeople = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.accountPeople);

export const getAccountPersonExpandedRowState = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.expandedRowState);

export const getAccountPersonExpandedReport = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.expandedReport);

export const getAccountSelectedActivityTypeOption = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.selectedAccountTypeOption);

export const getAccountSelectedOpportunityOption = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.selectedOpportunityOption);

export const getAccountSpecificAdvertising = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.accountAdvertising);

export const getAccountSpecificTable = createSelector(
  getAccountSpecificActiveTabId,
  getAccountSpecificOpportunities,
  getAccountSpecificTouches,
  getAccountSpecificPeople,
  getAccountSpecificSales,
  getAccountSpecificAdvertising,
  (
    key: AccountAttributionTabs,
    opportunities: IAccountOpportunity[],
    touches: IAccountTouches,
    people: IAccountPeople,
    sales: IAccountSalesData,
    advertising: IAccountAdvertising[],
  ): IAccountTableData[] => {
    switch (key) {
      case AccountAttributionTabs.Opportunities:
        return opportunities?.map(item => {
          return {
            ...item,
            salesActivities: +sales?.opptyTaskCount[item.id]
          }
        })

      case AccountAttributionTabs.People:
        return people.contacts.map(item => {
          return {
            ...item,
            sourceCampaignName: item.sourceCampaign.name,
            campaignMembers: touches.countsById && +touches.countsById[item.leadId] || 0,
          }
        });

      case AccountAttributionTabs.OrphanLeads:
        return people.orphanLeads.map(item => {
          return {
            ...item,
            ...item.campaignMember,
              confidence: item.score <= ACCOUNT_SCORE_LOW_MARK ? 'low' : item.score <= ACCOUNT_SCORE_HIGH_MARK ? 'medium' : 'high'
          }
        });

      case AccountAttributionTabs.CampaignResponses:
        return touches.activityList.map(item => {
          return {
            ...item,
            campaignName: item.campaign.name,
            contactName: item.contact.name,
          }
        });

      case AccountAttributionTabs.SalesActivities:
        return sales.tasks

      case AccountAttributionTabs.Advertising:
        return advertising;
    }
  });

export const  getAccountSpecificSearch = createSelector(
  getAccountSpecificActiveTab,
  (activeTab: IAccountAttributionTab) => activeTab.table.searchQuery);

export const getAccountSpecificSortedTable = createSelector(
  getAccountSpecificTable,
  getAccountSpecificActiveTab,
  getAccountSpecificSearch,
  (data: IAccountTableData[], activeTab: IAccountAttributionTab, query: string) => {
    const dataAfterApplyingSearch = searchInReportData(data, query);
    return sortReportData(dataAfterApplyingSearch, activeTab.table.sortState);
  }
);

export const getAccountSpecificSortedTableWithPagination = createSelector(
  getAccountSpecificSortedTable,
  getAccountSpecificActiveTab,
  (data: IAccountTableData[], activeTab: IAccountAttributionTab): IAccountTableData[] =>
    applyPageToReportData(data, activeTab.table.pagination.page)
);

export const getAccountOverviewChart = createSelector(
  getAccountSpecificSales,
  getAccountSpecificTouches,
  getAccountSpecificAnonymousWeb,
  (sales: IAccountSalesData,
    touches: IAccountTouches,
    anonymousWeb: IAccountAnonymousWeb,
  ): IAccountChartData[] => {
    const chartData: Record<number, IAccountChartData> = {};
    const calculateDate = (key: string) => {
      const [year, week] = key.split(' ');
      return startOfISOWeek(setISOWeek(setYear(new Date(), +year), +week)).getTime();
    }
    if (sales) {
      sales.tasks.forEach(item => {
        const date = calculateDate(item.weekOfYear);
        if (chartData[date]) {
          chartData[date].salesActivities++;
        } else {
          chartData[date] = {
            pageViews: 0,
            salesActivities: 1,
            marketingActivities: 0,
            intentSurge: 0,
            date,
          }
        }
      });
    }

    if (touches?.activityList.length) {
      touches.activityList.forEach(item => {
        const date = calculateDate(item.weekOfYear);
        if (chartData[date]) {
          chartData[date].marketingActivities++
        } else {
          chartData[date] = {
            pageViews: 0,
            salesActivities: 0,
            marketingActivities: 1,
            intentSurge: 0,
            date,
          }
        }
      });
    }

    if (anonymousWeb?.intentTopics.length) {
      anonymousWeb?.intentTopics.forEach(item => {
        const date = calculateDate(item.weekOfYear);
        if (chartData[date]) {
          chartData[date].intentSurge += item.topics.length;
        } else {
          chartData[date] = {
            pageViews: 0,
            salesActivities: 0,
            marketingActivities: 0,
            intentSurge: item.topics.length,
            date,
          }
        }
      });
    }

    if (anonymousWeb?.pageViews.length) {
      anonymousWeb?.pageViews.forEach(item => {
        const date = calculateDate(item.weekOfYear);
        if (chartData[date]) {
          chartData[date].pageViews += item.counts;
        } else {
          chartData[date] = {
            pageViews: item.counts,
            salesActivities: 0,
            marketingActivities: 0,
            intentSurge: 0,
            date,
          }
        }
      });
    }

    return Object.keys(chartData)
      .sort((a, b) => compare(a, b, true))
      .map(key => chartData[key]);
  });

export const getAccountEngagmentSpikesChartData = createSelector(
  getAccountSpecificAnonymousWeb,
  (accountAnonymousWeb: IAccountAnonymousWeb) => calculateEngagementSpikeCounts(accountAnonymousWeb?.spikeModels || [])
);

export const getAccountEngagmentSpikesMetrics = createSelector(
  getAccountEngagmentSpikesChartData,
  (data: { model: string; count: number; previous: number; }[]) => [
    {
      current: {
        value: data.reduce((sum, item) => sum + item.count || 0, 0),
        dataType: DataTypeEnum.Number,
        color: AccountSpecificColors.Sales
      },
    }
  ]);

export const getAccountMappedOpptyHistory = createSelector(
  getAccountSpecificOpportunities,
  getAccountSpecificOpptyHistory,
  (opportunities: IAccountOpportunity[], opptyHistory: IAccountOpptyHistory) =>
    chartOpptyHistoryMapper(opportunities || [], opptyHistory)
);

export const getAccountOpportunityOptions = createSelector(
  getAccountMappedOpptyHistory,
  (stages): ILabelValue[] => [{ label: 'None', value: null }]
    .concat(stages.map(stage => ({ label: stage.name, value: stage.opportunityId })))
);

export const getAccountSpecificActivityList = createSelector(
  getAccountSpecificTouches,
  getAccountSpecificSales,
  getAccountSpecificAnonymousWeb,
  getAccountSpecificOpportunities,
  getAccountSpecificOpptyHistory,
  (touches: IAccountTouches,
    sales: IAccountSalesData,
    anonymousWeb: IAccountAnonymousWeb,
    opportunities: IAccountOpportunity[],
    opptyHistory: IAccountOpptyHistory,
  ) => marketingMapper(touches?.activityList || [])
        .concat(salesMapper(sales?.tasks || []))
        .concat(anonymousMapper(anonymousWeb))
        .concat(opptyMapper(opportunities, opptyHistory))
        .concat(opptyHistoryMapper(opptyHistory))
);

export const getAccountPersonTouches = createSelector(
  getAccountSpecificPeople,
  getAccountSpecificActivityList,
  (people: IAccountPeople, activities: IAccountActivity[]): IAccountPerson[] =>
  (people?.contacts || []).map(person => {
    const activityList = sortReportData(
      activities.filter(activity => activity.contactID === person.id),
      { active: 'date', direction: SortDirections.Desc }
    );
    return {
      ...person,
      activityList,
      lastActivityDate: activityList[0]?.date || '',
      activitiesCounts: activityList.length
    };
  })
);

export const getAccountSpecificReportTable = createSelector(
  selectAccountSpecificState,
  (state: AccountSpecificState) => state.reportTable);

export const getAccountPeopleData = createSelector(
  getAccountPersonTouches,
  getAccountSpecificReportTable,
  (data: IAccountPerson[], reportTable: IReportTable) =>
    applyPageToReportData(
      sortReportData(data, reportTable.sortState),
      reportTable.pagination.page
    )
);

export const getAccountPersonTouchesTotals = createSelector(
  getAccountPersonTouches,
  (persons) => persons.length
);

export const getAccountPeopleExpandedData = createSelector(
  getAccountPersonExpandedReport,
  (expandedReport) => Object.keys(expandedReport)
    .map(id => ({
        id,
        totals: expandedReport[id].data.length,
        data: applyPageToReportData(
          sortReportData(expandedReport[id].data, expandedReport[id].reportTable.sortState),
          expandedReport[id].reportTable.pagination.page,
          expandedReport[id].reportTable.pagination.perPage
        ),
        reportTable: expandedReport[id].reportTable
      }))
    .reduce((accum, item) => {
      accum[item.id] = { data: item.data, reportTable: item.reportTable, totals: item.totals };
      return accum;
    }, {})
)

export const getAccountActivitiesAllData = createSelector(
  getAccountSpecificActivityList,
  getAccountSelectedActivityTypeOption,
  (data: IAccountActivity[], selectedActivityTypeOption: ILabelValue<ActivityType>) =>
    selectedActivityTypeOption.value === ActivityType.All
      ? data
      : data.filter(activity => activity.touchType === selectedActivityTypeOption.value)
);

export const getAccountActivitiesData = createSelector(
  getAccountActivitiesAllData,
  getAccountSpecificReportTable,
  (data: IAccountActivity[], reportTable: IReportTable) =>
    applyPageToReportData(
      reportTable.sortState.active !== 'date'
        ? sortReportData(data, reportTable.sortState)
        : reportTable.sortState.active
          ? sortByDate(data, 'date', reportTable.sortState.direction === SortDirections.Asc)
          : data,
      reportTable.pagination.page
    )
);

export const getAccountDetailsDate = createSelector(
  getAccountSpecificInfo,
  getAccountSpecificAnonymousWeb,
  (info: IAccountInfo,
    anonymousWeb: IAccountAnonymousWeb,
  ) => {

    const lastIntentTopics = sortByDate(anonymousWeb?.intentTopics || [], 'date', true).slice(-1).pop();
    const lastSpikeModels = sortByDate(anonymousWeb?.spikeModels || [], 'date', true).slice(-1).pop();

    return {
      lastIntentTopics: lastIntentTopics?.topics.join(', ') || '-',
      lastIntentDate: lastIntentTopics?.date && format(new Date(lastIntentTopics.date), 'MMMM d, yyyy') || '-',
      spikeModel: lastSpikeModels?.models.join(', ') || '-',
      lastSpikeDate: lastSpikeModels?.date && format(new Date(lastSpikeModels.date), 'MMMM d, yyyy') || '-',
      propensityToPurchase: info?.propensityToPurchase || '-',
      relationshipScore: info?.relationshipScore,
      employeeRange: info?.employeeRange || '-',
      industry: info?.industry || '-',
      revenueRange: info?.revenueRange || '-',
    }
  }
);

export const getAccountSpecificInfoWithTotals = createSelector(
  getAccountSpecificInfo,
  getAccountSpecificOpportunities,
  (info: IAccountInfo, opprtunities: IAccountOpportunity[]) => ({
    ...info,
    totalOpenOpportunityValue: (opprtunities || [])
      .filter(opportunity => opportunity.isOpen)
      .reduce((sum, curr) => sum + curr.amount, 0),
    totalClosedWonRevenue: (opprtunities || [])
      .filter(opportunity => !opportunity.isOpen && opportunity.won)
      .reduce((sum, curr) => sum + curr.amount, 0),
  })
);

export const getAccountSpecificCalculatedLastAnonymousWeb = createSelector(
  getAccountSpecificCalculatedAnonymousWeb,
  (topics: IAccountCalulatedIntentTopic[]) => sortByDate(topics || [], 'date', false)
);

export const getAccountLastActivitiesData = createSelector(
  getAccountActivitiesAllData,
  (data: IAccountActivity[]) => sortByDate(data || [], 'date', false)
);

