import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RouteItemEnum } from '@shared/enums';
import { AccountSpecificComponent } from './containers/account-specific.component';

const routes: Routes = [
  {
    path: '',
    component: AccountSpecificComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'attribution',
      },
      {
        path: 'attribution',
        loadChildren: () => import('./account-attribution/account-attribution.module').then(m => m.AccountAttributionModule),
        data: {
          routeId: RouteItemEnum.AccountSpecificAttribution,
          title: 'Account Hub - Data Studio',
          parent: {
            title: 'Account Hub',
            routeId: RouteItemEnum.AccountHub,
          },
        }
      },
      {
        path: 'trending',
        loadChildren: () => import('./account-details/account-details.module').then(m => m.AccountDetailsModule),
        data: {
          routeId: RouteItemEnum.AccountSpecificTrending,
          title: 'Account Hub - Data Studio',
          parent: {
            title: 'Account Hub',
            routeId: RouteItemEnum.AccountHub,
          },
        }
      },
      {
        path: 'people',
        loadChildren: () => import('./account-people/account-people.module').then(m => m.AccountPeopleModule),
        data: {
          routeId: RouteItemEnum.AccountSpecificPeople,
          title: 'Account Hub - Data Studio',
          parent: {
            title: 'Account Hub',
            routeId: RouteItemEnum.AccountHub,
          },
        }
      },
      {
        path: 'activities',
        loadChildren: () => import('./account-activities/account-activities.module').then(m => m.AccountActivitiesModule),
        data: {
          routeId: RouteItemEnum.AccountSpecificActivities,
          title: 'Account Hub - Data Studio',
          parent: {
            title: 'Account Hub',
            routeId: RouteItemEnum.AccountHub,
          },
        }
      },
    ]
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountSpecificRoutingModule { }
