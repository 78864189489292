import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import camelcaseKeys from 'camelcase-keys';

import { HttpClientBase } from '@shared/http-client-base';
import { IAccountSpecificSource } from './account-specific.source.interface';
import { IAccountSpecificParams } from '../interfaces/account-params.interface';
import { IAccountAdvertising } from '../interfaces/account-advertising.interface';
import { IAccountInfo } from '../interfaces/account-info.interface';
import {IAccountSalesData, IAccountSalesDataPayload} from '../interfaces/account-sales-data.interface';
import { IAccountPeople } from '../interfaces/account-people.interface';
import { IAccountTouches } from '../interfaces/account-touches.interface';
import { IAccountOpportunity } from '../interfaces/account-opportunity.interface';
import { IAccountAnonymousWeb } from '../interfaces/account-anonymous_web.interface';
import { IAccountOpptyHistory } from '../interfaces/account-oppty-history.interface';
import { IAccountTrendingInfoParams } from '../interfaces/account-trending-info-params.interface';
import { IAccountTrendingInfo } from '../interfaces/account-trending-info.interface';
import { getHttpParams } from '@util/http';

export enum AccountSpecificSourceURLs {
  GetAccountInfo = '/account/load_info',
  GetAccountAdvertising = '/account/load_advertising',
  GetSalesData = '/account/load_sales_data',
  GetPeople = '/account/load_people',
  GetTouches = '/account/load_touches',
  GetOpportunities = '/account/load_opportunities',
  GetAnonymousWeb = '/account/load_anonymous_web',
  GetOpptyHistory = '/account/load_oppty_history',
  GetTrendingInfo = '/account/load_trending_info',
}

@Injectable()
export class AccountSpecificSource extends HttpClientBase implements IAccountSpecificSource {
  getAccountInfo$(params: string): Observable<IAccountInfo> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetAccountInfo);

    return this.httpClient.get<IAccountInfo>(endpoint, {
      params: getHttpParams({ accountId: params }),
    });
  }

  getAccountSalesData$(params: IAccountSpecificParams): Observable<IAccountSalesDataPayload> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetSalesData);
    return this.httpClient.get<IAccountSalesDataPayload>(endpoint, {
      params: getHttpParams(params),
    });
  }

  getAccountPeople$(params: IAccountSpecificParams): Observable<IAccountPeople> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetPeople);

    return this.httpClient.get<IAccountPeople>(endpoint, {
      params: getHttpParams(params),
    });
  }

  getAccountTouches$(params: IAccountSpecificParams): Observable<IAccountTouches> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetTouches);

    return this.httpClient.get<IAccountTouches>(endpoint, {
      params: getHttpParams(params),
    }).pipe(
      map(response => {
        const convertedResponse = response['activityList'].map(item => {
          const camelCaseItem = camelcaseKeys(item);
          Object.keys(camelCaseItem).forEach(key => {
            if (typeof camelCaseItem[key] === 'object' && camelCaseItem[key] !== null) {
              camelCaseItem[key] = camelcaseKeys(camelCaseItem[key])
            }
          });
          return camelCaseItem
        });
        return {
          activityList: convertedResponse,
          countsById: response['counts_by_id']
        };
      })
    );
  }

  getAccountOpportunities$(params: IAccountSpecificParams): Observable<IAccountOpportunity[]> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetOpportunities);

    return this.httpClient.get<IAccountOpportunity[]>(endpoint, {
      params: getHttpParams(params),
    });
  }

  getAccountAdvertising$(params: IAccountSpecificParams): Observable<IAccountAdvertising[]> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetAccountAdvertising);

    return this.httpClient.get<IAccountAdvertising[]>(endpoint, {
      params: getHttpParams(params),
    });
  }

  getAccountAnonymousWeb$(params: IAccountSpecificParams): Observable<IAccountAnonymousWeb> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetAnonymousWeb);

    return this.httpClient.get<IAccountAnonymousWeb>(endpoint, {
      params: getHttpParams(params),
    });
  }

  getAccountOpptyHistory$(params: IAccountSpecificParams): Observable<IAccountOpptyHistory> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetOpptyHistory);

    return this.httpClient.get<IAccountOpptyHistory>(endpoint, {
      params: getHttpParams(params),
    });
  }

  getAccountTrendingInfo$(params: IAccountTrendingInfoParams): Observable<IAccountTrendingInfo> {
    const endpoint = this.endpoint(AccountSpecificSourceURLs.GetTrendingInfo);

    return this.httpClient.get<IAccountTrendingInfo>(endpoint, {
      params: getHttpParams(params),
    });
  }
}
