import { ActivityType } from '../enums/activity-type.enum';
import { ILabelValue } from '@shared/interfaces';

export const AccountActivityTypeIcons = {
  [ActivityType.Marketing]: 'fas fa-megaphone',
  [ActivityType.Sales]: 'fas fa-comments-alt-dollar',
  [ActivityType.Anonymous]: 'fas fa-binoculars',
  [ActivityType.Opportunity]: 'fas fa-filter',
}

export const AccountActivityTypeDescription = {
  [ActivityType.All]: 'dataStudio.features.accountSpecific.activityType.all',
  [ActivityType.Marketing]: 'dataStudio.features.accountSpecific.activityType.marketingActivity',
  [ActivityType.Sales]: 'dataStudio.features.accountSpecific.activityType.salesActivity',
  [ActivityType.Anonymous]: 'dataStudio.features.accountSpecific.activityType.anonymousActivity',
  [ActivityType.Opportunity]: 'dataStudio.features.accountSpecific.activityType.opportunityActivity',
}

export const AccountTypeOptions: ILabelValue<ActivityType>[] = [{
  label: AccountActivityTypeDescription[ActivityType.All],
  value: ActivityType.All,
}, {
  label: AccountActivityTypeDescription[ActivityType.Marketing],
  value: ActivityType.Marketing,
}, {
  label: AccountActivityTypeDescription[ActivityType.Sales],
  value: ActivityType.Sales,
}, {
  label: AccountActivityTypeDescription[ActivityType.Opportunity],
  value: ActivityType.Opportunity,
}, {
  label: AccountActivityTypeDescription[ActivityType.Anonymous],
  value: ActivityType.Anonymous,
}];
