import { Inject, Injectable } from '@angular/core';

import { combineLatest, Observable, of, throwError } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { RouteItemEnum, TileTypes } from '@shared/enums';
import {
  DefaultTileInformation,
  IAccountListDataParams,
  IDashboardTile,
  IMetricInsightsParams,
  IReportsAccess,
  IRouteItem,
  ITileData,
  ITileGaugeData,
  ITileSettingControl,
  ITileSettings,
  ITileVisualizationConfig
} from '@shared/interfaces';
import { DEFAULT_ACCOUNT_TILE_INFORMATION } from '@shared/constants';
import * as UserSelectors from '@user/user.selectors';
import { orgConfigActions, getSelectedTerminusData } from '@org-config';
import { campaignsActions } from '@shared/data-access/campaigns';
import { AccountHubSharedService } from '@shared/data-access/account-hub';
import { APP_ROUTES } from '@app-routes';
import { ITileModalData } from '@shared/data-access/tile-modal';

@Injectable()
export class TileDataService implements ITileModalData {
  constructor(public sharedAccountHubService: AccountHubSharedService,
    private store: Store<unknown>,
    @Inject(APP_ROUTES) private applicationRoutes: IRouteItem[]) {
  }

  getTileData$(tile: IDashboardTile): Observable<ITileData | ITileGaugeData> {
    const params = tile.settings;
    switch (tile.type) {
      case TileTypes.EngagementInsights:
      case TileTypes.AccountsList:
        return this.sharedAccountHubService.getTileData$(params as unknown as IMetricInsightsParams | IAccountListDataParams,
          null,
          tile.type);

      default:
        return throwError(`Unknown report: ${tile.route}`);
    }
  }

  // Settings for chart and table
  getTileVisualizationConfig$(tile: IDashboardTile): Observable<ITileVisualizationConfig> {
    switch (tile.route) {
      case RouteItemEnum.AccountHub:
        return this.sharedAccountHubService.getTileVisualizationConfig$(tile);

      default:
        return of(null);
    }
  }

  getTileSettingsFilters$(type: TileTypes): Observable<ITileSettingControl[] | null> {
    switch (type) {
      case TileTypes.EngagementInsights:
      case TileTypes.AccountsList:
        return this.sharedAccountHubService.getTileSettingsFilters$(type);

      default:
        console.error(`Couldn't find tile's type ${type}`);
        return of(null);
    }
  }

  // get all available tile after checking permission by category
  // add default settings for each tile
  getAvailableTiles$(): Observable<Record<TileTypes, DefaultTileInformation>> {
    return combineLatest([
      this.store.pipe(select(UserSelectors.selectUserProfileReportsAccess)),
      this.store.pipe(select(getSelectedTerminusData))
    ]).pipe(
      map(([access, isTerminusData]: [IReportsAccess, boolean]) => {
        const types = Object.keys(DEFAULT_ACCOUNT_TILE_INFORMATION) as TileTypes[];
        return types.reduce((acc, type: TileTypes) => {
          // return tile if it's available or in case of revenue attribution category
          // return disabled or not disabled tile
          const checkedTile = this.checkTileAccess(
            DEFAULT_ACCOUNT_TILE_INFORMATION[type],
            isTerminusData,
            access
          );
          if (checkedTile) {
            const settings = this.getTileSettingsByType(type);
            acc[type] = {
              ...checkedTile,
              settings: {
                ...checkedTile.settings,
                ...settings
              }
            };
          }

          return acc;
        }, {} as Record<TileTypes, DefaultTileInformation>);
      }),
    );
  }

  // Settings for tile modal form
  getTileSettingsByType(type: TileTypes, params?: ITileSettings): ITileSettings {
    switch (type) {
      case TileTypes.EngagementInsights:
      case TileTypes.AccountsList:
        return this.sharedAccountHubService.getTileDefaultSettings(params, type);

      default:
        console.error(`Couldn't find tile's type ${type}`);
        return {} as ITileSettings;
    }
  }

  loadOptions(): void {
    this.store.dispatch(orgConfigActions.LoadSpikeModels());
    this.store.dispatch(campaignsActions.getCampaignTypes());
  }

  private checkTileAccess(tile: DefaultTileInformation,
    isTerminusData: boolean,
    reportAccess: IReportsAccess
  ): DefaultTileInformation | null {
    // Check EngagementInsights separately because it depends
    // on terminus data from org config
    if (tile.type === TileTypes.EngagementInsights) {
      return isTerminusData ? tile : null;
    }
    const routeInformation = this.getRouteItem(tile.route);
    // if there is no route which we use in the tile then return null
    // there is some kind of error
    if (!routeInformation) {
      console.error('No route for this tile');
      return null;
    }

    // check other tiles and add them to the result if there is access to this route
    // otherwise remove from the result
    if ((routeInformation.alwaysAdd || reportAccess[tile.route])) {
      return tile;
    }

    return null;
  }

  private getRouteItem(routeId: RouteItemEnum): IRouteItem | null {
    return this.applicationRoutes.find(route => route.routeId === routeId);
  }
}
