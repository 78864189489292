import { Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';

import { Observable, of } from 'rxjs';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { EnvService } from '@shared/environment';
import { IAccountInfo } from '../interfaces/account-info.interface';
import { AccountSpecificSource } from '../sources/account-specific.source';
import { IAccountSpecificParams } from '../interfaces/account-params.interface';
import { IAccountAdvertising } from '../interfaces/account-advertising.interface';
import { IAccountOpportunity } from '../interfaces/account-opportunity.interface';
import { IAccountOpptyHistory } from '../interfaces/account-oppty-history.interface';
import { IAccountAnonymousWeb } from '../interfaces/account-anonymous_web.interface';
import { IAccountTouches } from '../interfaces/account-touches.interface';
import { IAccountPeople } from '../interfaces/account-people.interface';
import {IAccountSalesData, IAccountSalesDataPayload} from '../interfaces/account-sales-data.interface';
import { hubTokenName } from '@shared/constants';
import { ToDate } from '@shared/enums';
import { getStartAndEndDateFromParams, replaceLegacyCohort } from '@util/helpers';
import { IAccountTrendingInfoParams } from '../interfaces/account-trending-info-params.interface';
import { IAccountTrendingInfo } from '../interfaces/account-trending-info.interface';

@Injectable()
export class AccountSpecificService {

  constructor(
    public source: AccountSpecificSource,
    public injector: Injector,
    public retry: RetryWithEscalation,
    public envService: EnvService
  ) {
    source.podPath = of(envService.getEnv().GRAILS_URL);
  }

  getAccountInfo$(params: string): Observable<IAccountInfo> {
    return regenerateOnRetry(() =>  this.source.getAccountInfo$(params))
        .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountOpportunities$(params: IAccountSpecificParams): Observable<IAccountOpportunity[]> {
    return regenerateOnRetry(() =>  this.source.getAccountOpportunities$(params))
        .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountAdvertising$(params: IAccountSpecificParams): Observable<IAccountAdvertising[]> {
    return regenerateOnRetry(() =>  this.source.getAccountAdvertising$(params))
        .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountSalesData$(params: IAccountSpecificParams): Observable<IAccountSalesDataPayload> {
    return regenerateOnRetry(() =>  this.source.getAccountSalesData$(params))
        .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountSpecificFiltersFromParams(params: Params): IAccountSpecificParams {
    // get query params from url or use default params
    const defaultQueryParams: IAccountSpecificParams = {
      accountId: '',
      cohort: ToDate.QUARTER_TO_DATE,
      endDate: null,
      startDate: null,
      timeUnit: 'month',
      withSalesActivity: true
    };

    const filters = {
      ...defaultQueryParams,
      ...params,
      cohort: replaceLegacyCohort(params.cohort) || defaultQueryParams.cohort,
      endDate: params.endDate,
      startDate: params.startDate,
    };

    return {
      ...filters,
      ...getStartAndEndDateFromParams(filters.cohort, filters.startDate, filters.endDate)
    };
  }

  getAccountPeople$(params: IAccountSpecificParams): Observable<IAccountPeople> {
    return regenerateOnRetry(() =>  this.source.getAccountPeople$(params))
          .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountTouches$(params: IAccountSpecificParams): Observable<IAccountTouches> {
    return regenerateOnRetry(() =>  this.source.getAccountTouches$(params))
          .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountAnonymousWeb$(params: IAccountSpecificParams): Observable<IAccountAnonymousWeb> {
    return regenerateOnRetry(() =>  this.source.getAccountAnonymousWeb$(params))
          .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountOpptyHistory$(params: IAccountSpecificParams): Observable<IAccountOpptyHistory> {
    return regenerateOnRetry(() =>  this.source.getAccountOpptyHistory$(params))
          .pipe(this.retry.retryWithEscalation(hubTokenName));
  }

  getAccountTrendingInfo$(params: IAccountTrendingInfoParams): Observable<IAccountTrendingInfo> {
    return regenerateOnRetry(() =>  this.source.getAccountTrendingInfo$(params))
          .pipe(this.retry.retryWithEscalation(hubTokenName));
  }
}

