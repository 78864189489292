/**
 * NOTE: In order to achieve build-once-deploy-anywhere,
 * we are deviating from the normal "Angular way" with
 * regards to envronment.ts.
 * DO NOT add environment specific values here and import this file directly.
 * Instead, add them to environments/environment.json (with values that work with ninja)
 * ONLY add values into this file which should change based on build Configuration (development/prod builds)
 *
 * Note that prod builds also get deployed to ninja on merges to master, so there is a distinction
 * between a prod configuration build and prod deployment environment.
 *
 * TODO: add a link to a writeup on man on what's going on here
 */
export const environment = {
  production: true,
};
