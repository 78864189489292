import { GoParams, IReportColumn } from '@shared/interfaces';
import { DataTypeEnum, RouteItemEnum } from '@shared/enums';
import { IAccountTableData } from '../interfaces/account-table.interface';
import { getQueryString } from '@util/http';

export const CREATED_DATE_COLUMN_NAME = 'createdDate';
export const OPPORTUNITIES_COLUMNS: IReportColumn[] = [
  {
    name: 'name',
    width: 400,
    displayName: 'dataStudio.features.accountSpecific.table.opportunity',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'opportunityType',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.type',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'stage',
    width: 120,
    displayName: 'dataStudio.features.accountSpecific.table.stage',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'amount',
    width: 120,
    displayName: 'dataStudio.features.accountSpecific.table.amount',
    dataType: DataTypeEnum.Currency,
    visible: true
  }, {
    name: 'salesActivities',
    width: 210,
    displayName: 'dataStudio.features.accountSpecific.table.salesActivity',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: CREATED_DATE_COLUMN_NAME,
    width: 135,
    displayName: 'dataStudio.features.accountSpecific.table.createdDate',
    dataType: DataTypeEnum.Date,
    visible: true
  }, {
    name: 'closeDate',
    width: 135,
    displayName: 'dataStudio.features.accountSpecific.table.closeDate',
    dataType: DataTypeEnum.Date,
    visible: true
  }
];
export const PEOPLE_COLUMNS: IReportColumn[] = [
  {
    name: 'name',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.person',
    dataType: DataTypeEnum.Text,
    link(row: IAccountTableData): GoParams {
      return {
        routeId: RouteItemEnum.AccountSpecificPeople,
        queryParams: {
          id: row.contactId || row.leadId
        }
      }
    },
    visible: true
  }, {
    name: 'sourceCampaignName',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.sourceCampaign',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'marketingCounts',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.campaignResponses',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'type',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.relationship',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'salesCounts',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.salesActivity',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: CREATED_DATE_COLUMN_NAME,
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.createdDate',
    dataType: DataTypeEnum.Date,
    visible: true
  },
];

export const ORPHAN_LEADS_COLUMNS: IReportColumn[] = [
  {
    name: 'name',
    width: 400,
    displayName: 'dataStudio.features.accountSpecific.table.person',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'count',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.campaignMember',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'salesActivity',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.salesActivity',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: CREATED_DATE_COLUMN_NAME,
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.created',
    dataType: DataTypeEnum.Date,
    visible: true
  }, {
    name: 'lastActivity',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.lastActivity',
    dataType: DataTypeEnum.Date,
    visible: true
  }, {
    name: 'confidence',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.confidence',
    dataType: DataTypeEnum.Text,
    visible: true
  }
];

export const CAMPAIGN_RESPONSES_COLUMNS: IReportColumn[] = [
  {
    name: 'activityDate',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.date',
    dataType: DataTypeEnum.Date,
    visible: true
  }, {
    name: 'campaignName',
    width: 400,
    displayName: 'dataStudio.features.accountSpecific.table.campaign',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'contactName',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.person',
    dataType: DataTypeEnum.Text,
    link(row: IAccountTableData): GoParams {
      return {
        routeId: RouteItemEnum.Salesforce,
        queryParams: {
          id: row.contact?.id || row.leadId
        }
      }
    },
    visible: true
  }
];

export const SALES_ACTIVITY_COLUMNS: IReportColumn[] = [
  {
    name: 'type',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.type',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: CREATED_DATE_COLUMN_NAME,
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.date',
    dataType: DataTypeEnum.Date,
    visible: true
  }, {
    name: 'name',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.person',
    dataType: DataTypeEnum.Text,
    link(row: IAccountTableData): GoParams {
      return {
        routeId: RouteItemEnum.Salesforce,
        queryParams: {
          id: row.contactId || row.leadId
        }
      }
    },
    visible: true
  }, {
    name: 'assignedTo',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.assignedTo',
    dataType: DataTypeEnum.Text,
    visible: true
  }, {
    name: 'subject',
    width: 200,
    displayName: 'dataStudio.features.accountSpecific.table.subject',
    dataType: DataTypeEnum.Text,
    visible: true
  }
];

export const ADVERTISING_COLUMNS = [{
  name: 'impressions',
  width: 200,
  displayName: 'dataStudio.features.accountSpecific.table.impressions',
  dataType: DataTypeEnum.Text,
  visible: true
}, {
  name: 'clicks',
  width: 200,
  displayName: 'dataStudio.features.accountSpecific.table.clicks',
  dataType: DataTypeEnum.Text,
  visible: true
}, {
  name: 'pageViews',
  width: 200,
  displayName: 'dataStudio.features.accountSpecific.table.pageViews',
  dataType: DataTypeEnum.Text,
  visible: true
}, {
  name: 'visitDateUtc',
  width: 200,
  displayName: 'dataStudio.features.accountSpecific.table.visitDateUtc',
  dataType: DataTypeEnum.Date,
  visible: true
}, {
  name: 'uniqueVisitors',
  width: 200,
  displayName: 'dataStudio.features.accountSpecific.table.uniqueVisitors',
  dataType: DataTypeEnum.Text,
  visible: true
}];

export const attributionLinkFactory = function (routeItem, env) {
  return (row: IAccountTableData) => {
    return {
      path: [
        `${env[routeItem.externalUrlKey]}${routeItem.routeLink[0]}`,
        getQueryString({oppty: row.id, isId: true})
      ]
    };
  }
}
