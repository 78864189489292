import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ReportAccessGuard } from '@shared/data-access/guards';

import { HubClaimsKeys, TokenClaimsGuard } from '@hub-token';
import { hubTokenName, LOGIN_ROUTE_PATH, PROFILE_ROUTE, SIGN_OUT_ROUTE } from '@shared/constants';
import { GlobalFiltersResolver } from '@shared/data-access/global-filters';
import { RouteItemEnum } from '@shared/enums';
import { AppContainerComponent } from './containers/app/app-container.component';


const routes: Route[] = [
  {
    path: '',
    component: AppContainerComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/list-analysis',
      },
      {
        path: '',
        children: [
          {
            path: 'list-analysis',
            loadChildren: () => import('@data-studio/features/account-hub').then(m => m.AccountHubModule),
            canActivate: [
              ReportAccessGuard,
            ],
            data: {
              title: 'Account Hub - Data Studio',
              routeId: RouteItemEnum.AccountHub
            }
          },
          {
            path: 'account-specific',
            loadChildren: () => import('@data-studio/features/account-specific').then(m => m.AccountSpecificModule),
            data: {
              title: 'Account Hub - Data Studio',
              routeId: RouteItemEnum.AccountSpecificAttribution,
            }
          },
          {
            path: 'demo-config',
            canActivate: [
              TokenClaimsGuard
            ],
            data: {
              routeId: RouteItemEnum.DemoConfig,
              title: 'Demo Mode - Data Studio',
              tokenClaimsGuard: {
                requiredTokenClaims: [
                  {
                    token: hubTokenName,
                    requiredClaims: [{ key: HubClaimsKeys.BFAdmin }]
                  }
                ]
              }
            },
            loadChildren: () => import('@shared/features/demo-config').then(m => m.DemoConfigModule)
          },
          {
            path: 'segments',
            loadChildren: () => import('@data-studio/features/segment-index').then(m => m.SegmentIndexModule),
            data: {
              title: 'Segments - Data Studio',
              routeId: RouteItemEnum.SegmentIndex,
            }
          }
        ], resolve: {
          globalFilters: GlobalFiltersResolver,
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('@data-studio/features/settings').then(m => m.SettingsModule),
        canActivate: [
          ReportAccessGuard
        ],
        data: {
          routeId: RouteItemEnum.Settings,
        }
      },
      {
        path: 'page-not-found',
        loadChildren: () => import('@shared/features/page-not-found').then(m => m.PageNotFoundModule),
        data: {
          title: 'Page Not Found',
        }
      },
      {
        path: 'auth_cont',
        loadChildren: () => import('@shared/features/auth-cont').then(m => m.AuthContModule),
      },
      {
        path: 'panic',
        loadChildren: () => import('@shared/features/panic').then(m => m.PanicModule),
        data: {
          title: 'Session Not Found'
        }
      },
      {
        path: SIGN_OUT_ROUTE,
        loadChildren: () => import('@shared/features/sign-out').then(m => m.SignOutModule),
      },
      {
        path: PROFILE_ROUTE,
        loadChildren: () => import('@shared/features/profile').then(m => m.ProfileModule),
      },
    ]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadChildren: () => import('@shared/features/login-page').then(m => m.LoginPageModule),
    data: {
      title: 'Login Page - Measurement Studio',
    }
  },
  {
    path: '**',
    redirectTo: '/page-not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],

  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
