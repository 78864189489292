import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { SetParentLinkDirective } from '@shared/classes';

@Component({
  selector: 'ds-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends SetParentLinkDirective implements OnInit, OnDestroy {

  constructor(
    public readonly store: Store<unknown>,
    public readonly router: Router,
    public route: ActivatedRoute,
  ) {
    super(router, route, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
