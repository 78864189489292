import { IAccountAnonymousWeb, IAccountCalulatedIntentTopic } from './interfaces/account-anonymous_web.interface';
import { IAccountPerson } from './interfaces/account-people.interface';
import { IAccountTask } from './interfaces/account-sales-data.interface';
import { IAccountTouch } from './interfaces/account-touches.interface';
import { AccountActivityTypeDescription } from './data/account-activity-type.data';
import { ActivityType } from './enums/activity-type.enum';
import { IAccountActivity } from './interfaces/account-activity.interface';
import { IAccountSpikeModel } from './interfaces/account-anonymous_web.interface';
import { IAccountMappedOpptyHistory } from './interfaces/account-mapped-oppty-history.interface';
import { IAccountOpportunity } from './interfaces/account-opportunity.interface';
import { IAccountOpptyHistory } from './interfaces/account-oppty-history.interface';
import { addSeconds, isAfter, isBefore, isEqual, addDays } from 'date-fns';

export const calculateAgregation = (contacts: IAccountPerson[], activityList: IAccountTouch[], tasks: IAccountTask[]): IAccountPerson[] => {
  const marketingContacts = calculateCounts(activityList || [], contacts || [], 'leadId', 'activityDate', 'marketingCounts');
  const salesContacts = calculateCounts(tasks || [], marketingContacts || [],'leadId', 'createdDate', 'salesCounts');

  return salesContacts.map(person => {
    person.totalCounts = person.marketingCounts + person.salesCounts;
    return person;
  });
};

const calculateCounts = (
  activities: IAccountTouch[] | IAccountTask[] ,
  contacts: IAccountPerson[],
  leadIdField: string,
  dateField: string,
  countsField: string
): IAccountPerson[] => {
  const countsMap = {};
  const lastTouchDate = {};

  function getMostRecentDate(currentDate: string, newDate: string): string {
    if(!currentDate) {
        return newDate;
    }
    return newDate > currentDate ? newDate : currentDate;
  }

  activities.forEach(activity => {
      countsMap[activity[leadIdField]] = countsMap[activity[leadIdField]] ? (countsMap[activity[leadIdField]] + 1) : 1;
      if(activity[dateField]) {
          lastTouchDate[activity[leadIdField]] = getMostRecentDate(lastTouchDate[activity[leadIdField]], activity[dateField]);
      }
  });

  return contacts.map(person => {
      person[countsField] = countsMap[person.leadId] ? countsMap[person.leadId] : 0;

      if(lastTouchDate[person.leadId]) {
          person.lastTouch = getMostRecentDate(person.lastTouch, lastTouchDate[person.leadId]);
      }
      return person;
  });
}

export const calculateIntentsCounts = (data: IAccountAnonymousWeb): IAccountCalulatedIntentTopic[] => {
  const value: { [key: string]: IAccountCalulatedIntentTopic } = {};
  data.intentTopics.forEach(intent => {
    const topicsDate = new Date(intent.date);
    intent.topics.forEach(topic => {
      value[topic] = {
        name: value[topic]?.name || topic,
        counts: (value[topic]?.counts || 0) + 1,
        date: value[topic]?.date && isAfter(value[topic]?.date, topicsDate) ? value[topic]?.date : topicsDate,
      };
    });
  });

  return Object.values(value);
}

export const calculateEngagementSpikeCounts = (data: IAccountSpikeModel[]) => {
  const agregateData = {};
  data.forEach(spike => {
    spike.models.forEach(model => {
      if (agregateData[model]) {
        agregateData[model].count++;
      } else {
        agregateData[model] = { count: 1, model, date: spike.date };
      }
    });
  });
  return Object.values(agregateData);
}

export const marketingMapper = (activityList: IAccountTouch[]): IAccountActivity[] =>
  activityList.map(m => ({
    date: m.activityDate,
    activityName: m.campaign.name,
    touchType: ActivityType.Marketing,
    activityType: m.campaign.revenueGroup,
    fullName: m.contact.name,
    contactID: m.contact.id,
    title: m.contact.title,
    email: m.contact.email,
    activityId: m.campaign.campaignMemberId,
    activityTypeDescription: AccountActivityTypeDescription[ActivityType.Marketing],
  }));

export const salesMapper = (tasks: IAccountTask[]): IAccountActivity[] =>
  tasks.map(s => ({
    date: s.createdDate,
    activityName: s.subject,
    touchType: ActivityType.Sales,
    activityType: s.type,
    fullName: s.name,
    contactID: s.leadId,
    title: s.title, email: s.email,
    activityId: s.activityId,
    activityTypeDescription: AccountActivityTypeDescription[ActivityType.Sales],
  }));

export const anonymousMapper = (anonymousData: IAccountAnonymousWeb): IAccountActivity[] => {
  const spikeModelTypes = {
    pageViews: 'Weekly Page Views',
    spikeModels: 'Weekly Engagement Spike',
    intentTopics: 'Weekly Intent Surge'
  }

  const getActivityName = (a) => {
      if (a.counts) {
          return a.counts;
      }
      return a.topics?.join(', ') || a.models?.join(', ');
  }

  const keys = Object.keys(anonymousData || {});
  if (!keys) {
      return [];
  }

  return keys.flatMap(key =>
    anonymousData[key].map((data, i) => ({
      date: new Date(data.date).toString(),
      activityName: getActivityName(data),
      touchType: ActivityType.Anonymous,
      activityType: spikeModelTypes[key],
      fullName: 'Anonymous',
      contactId: '',
      title: '',
      email: '',
      activityId: 'Anonymous' + i + getActivityName(data) + new Date(data.date).toString(),
      displayPageViewLabel: data.counts ? true : false,
      activityTypeDescription: AccountActivityTypeDescription[ActivityType.Anonymous],
    })));
}

export const opptyMapper = (opptyData: IAccountOpportunity[], opptyHistoryData: IAccountOpptyHistory): IAccountActivity[] => {
    const latestOpptyDates = calculateLatestStartDates(opptyHistoryData);

    const keys = opptyData || [];

    return keys.flatMap(t => {
        const mappedDataForOppty = [];
        mappedDataForOppty.push({
            date: t.createdDate,
            activityName: t.name,
            touchType: ActivityType.Opportunity,
            activityType: 'Opportunity Created',
            fullName: t.contactName || '',
            contactID: t.contactId,
            title: t.title || '',
            email: t.email || '',
            activityId: t.id + '--1',
            activityTypeDescription: AccountActivityTypeDescription[ActivityType.Opportunity],
        });
        if (t.closeDate) {
            mappedDataForOppty.push({
                date: calculateOpptyCloseDate(t.closeDate, latestOpptyDates[t.id]),
                activityName: t.name,
                touchType: ActivityType.Opportunity,
                activityType: 'Opportunity Closed',
                fullName: t.contactName || '',
                contactID: t.contactId,
                title: t.title || '',
                email: t.email || '',
                activityId: t.id + '--2',
                activityTypeDescription: AccountActivityTypeDescription[ActivityType.Opportunity],
            });
        }
        return mappedDataForOppty;
    });
}

export const opptyHistoryMapper = (opptyHistoryData: IAccountOpptyHistory): IAccountActivity[] => {

    const keys = Object.keys(opptyHistoryData || {});
    if (!keys) {
      return [];
    }

    return keys.flatMap(key =>
      Object.keys(opptyHistoryData[key].history).map(historyKey => {
        const t = opptyHistoryData[key].history[historyKey];
        return {
            date: t.stageStartDate,
            activityName: t.opptyName,
            touchType: ActivityType.Opportunity,
            activityType: 'Stage: ' + t.stageName,
            fullName: t.contactName || '',
            contactID: t.contactId,
            title: t.title || '',
            email: t.email || '',
            activityId: t.opportunityHistoryId,
            activityTypeDescription: AccountActivityTypeDescription[ActivityType.Opportunity],
        };
      }));
}

// We update the opportunity close date so that it is after the latest
// date among all the opportunity stages. This way the close will appear after
// all of it's stages
function calculateOpptyCloseDate(opptyCloseDate, latestOpptyDate): string {
    if (!latestOpptyDate) {
        return opptyCloseDate;
    }
    const latestOpptyDateTime = new Date(latestOpptyDate);
    const opptyCloseDateTime = new Date(opptyCloseDate);

    return isAfter(latestOpptyDateTime, opptyCloseDateTime) ? addSeconds(latestOpptyDateTime, 10).toString() : opptyCloseDate;
}

// Create a map from opportunity id to the latest date amongst all stages
function calculateLatestStartDates(opptyHistoryData: IAccountOpptyHistory): { [key: string]: string } {
  const latestOpptyDate = {};
  const keys = Object.keys(opptyHistoryData || {});
  if (!keys) {
    return {};
  }

  keys.forEach(key =>
    Object.keys(opptyHistoryData[key].history).map(historyKey => {
      const t = opptyHistoryData[key].history[historyKey];
      latestOpptyDate[t.opportunityId] = !latestOpptyDate[t.opportunityId]
          ? t.stageStartDate
          : isAfter(new Date(t.stageStartDate), new Date(latestOpptyDate[t.opportunityId]))
            ? t.stageStartDate
            : latestOpptyDate[t.opportunityId];
    })
  );

  return latestOpptyDate;
}

const normalizeOpptyHistoryData = (data: IAccountOpptyHistory, normalizedOppty: IAccountOpportunity[]): IAccountMappedOpptyHistory[] => {
  return Object.keys(data).map(key => {
    const opptyHistory: IAccountMappedOpptyHistory = {
      opportunityId: data[key]['opportunityId'],
      name: data[key].name,
      history: null,
    };

    const mappedHistory = Object.keys(data[key].history).map(stage => {
      const activity = data[key].history[stage];
      return {
        date: activity.stageStartDate,
        activityName: activity.opptyName,
        touchType: ActivityType.Opportunity,
        activityType: activity.stageName,
        fullName: activity.contactName || '',
        contactID: activity.contactId,
        title: activity.title || '',
        email: activity.email || '',
        activityId: activity.opportunityId,
        activityTypeDescription: '',
        value: 0,
        dateEnd: '',
        category: ''
      };
    });

    const targetOppty = normalizedOppty.find(record => record.id === opptyHistory.opportunityId);

    opptyHistory.history = sortByDate(mappedHistory, 'date', true);

    return {
      ...opptyHistory,
      history: opptyHistory.history.map((activity, index) => ({
        ...activity,
        dateEnd: index === opptyHistory.history.length -1
          ? (!targetOppty?.closeDate || isAfter(new Date(opptyHistory.history[index].date), new Date(targetOppty.closeDate))
              ? addDays(new Date(opptyHistory.history[index].date), 5)
              : new Date(targetOppty.closeDate)
            ).toISOString()
          : new Date(opptyHistory.history[index+1].date).toISOString()
      })),
    };
  })
  .concat(
    normalizedOppty
      .filter(targetOppty => !Object.values(data).find(opptyHistory => opptyHistory.opportunityId === targetOppty.id))
      .map(targetOppty => ({
        opportunityId: targetOppty.id,
        name: targetOppty.name,
        history: [{
          date: targetOppty.createdDate,
          activityName: targetOppty.name,
          touchType: ActivityType.Opportunity,
          activityType: targetOppty.name,
          fullName: targetOppty.contactName || '',
          contactID: targetOppty.contactId,
          title: targetOppty.title || '',
          email: targetOppty.email || '',
          activityId: targetOppty.id + '--1',
          activityTypeDescription: AccountActivityTypeDescription[ActivityType.Opportunity],
          value: 0,
          dateEnd: targetOppty.closeDate || '',
          category: ''
        }]
      }))
  );
}

const omitRetroactiveData = (stage, normalizedOppty: IAccountOpportunity[]) => {
  const targetOppty = normalizedOppty.find(record => record.id === stage.opportunityId);
  stage.opptyCreateDate = targetOppty ? targetOppty.createdDate : '';
  stage.opptyClosedDate = targetOppty ? targetOppty.closeDate : '';
  const before = isBefore(new Date(stage.opptyCreateDate), new Date(stage.opptyClosedDate));
  const same = isEqual(new Date(stage.opptyCreateDate), new Date(stage.opptyClosedDate));

  return (before || same) && stage;
};

export const chartOpptyHistoryMapper = (oppty: IAccountOpportunity[], opptyHistory: IAccountOpptyHistory) => {
  const normalizedOpptyHistory = normalizeOpptyHistoryData(opptyHistory, oppty);
  return normalizedOpptyHistory.filter(stage => {
      return omitRetroactiveData(stage, oppty);
  });
};

export const sortByDate = <T>(list: T[], field: string, ascending: boolean): T[] =>
  list.sort((a, b) => {
    const aDate = new Date(a[field]).getTime();
    const bDate = new Date(b[field]).getTime();
    return (isBefore(aDate, bDate) ? -1 : (isAfter(aDate, bDate) ? 1 : 0)) * (ascending ? 1 : -1);
  })
