import { RouteItemEnum } from '@shared/enums';

export const DATA_STUDIO_SETTINGS_ROUTE_ITEMS = [
  RouteItemEnum.HubspotConfigure,
  RouteItemEnum.Permissions,
  RouteItemEnum.PagesAndFeatures,
  RouteItemEnum.DataHygiene,
  RouteItemEnum.Stages,
  RouteItemEnum.DigitalAttribution,
];
