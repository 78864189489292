import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { IDateCohort, userMessageFactory } from '@shared/interfaces';
import { notificationMessagesActions } from '@notification-messages';
import { accountSpecificActions } from './account-specific.actions';
import * as AccountSpecificSelectors from './account-specific.selectors';
import { AccountSpecificService } from '../services/account-specific.service';
import { IAccountInfo } from '../interfaces/account-info.interface';
import { navMenusActions } from '@nav-menus';
import * as DateCohortsSelectors from '@date-cohorts';
import { DateCohortsService } from '@date-cohorts';
import * as OrgConfigSelectors from '@org-config';
import { IAccountSpecificParams } from '../interfaces/account-params.interface';
import {IAccountSalesData, IAccountSalesDataPayload, IAccountTask} from '../interfaces/account-sales-data.interface';
import { IAccountAttributionTab } from '../interfaces/account-tabs.interface';
import { IAccountTableData } from '../interfaces/account-table.interface';
import { DataTypeEnum } from '@shared/enums';
import { IAccountTrendingInfo } from '../interfaces/account-trending-info.interface';
import { DownloadCsvService } from '@shared/data-access/download-csv';
import { AccountSpecificState } from './account-specific.reducer';

@Injectable()
export class AccountSpecificEffects {
  constructor(
    public accountSpecificService: AccountSpecificService,
    public dateCohortService: DateCohortsService,
    private downloadService: DownloadCsvService,
    private translateService: TranslateService,
    private store: Store<AccountSpecificState>,
    private actions$: Actions,
  ) {
  }

  public onSetAccountFilters$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.setAccountFilters),
    distinctUntilChanged(null, action => JSON.stringify(action.params)),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSelectedTab))
      ),
    )),
    mergeMap(([_, selectedTab]) => {
      switch (selectedTab) {
        case 0:  //overview tab
          return [
            accountSpecificActions.loadAccountInfo(),
            accountSpecificActions.loadAccountOpportunities(),
            accountSpecificActions.loadAccountSales(),
            accountSpecificActions.loadAccountTouches(),
            accountSpecificActions.loadAccountPeople(),
            accountSpecificActions.loadAccountOpptyHistory(),
            accountSpecificActions.loadAccountAnonymousWeb(),
            accountSpecificActions.loadAdvertising(),
          ];

        case 1: //details
          return [
            accountSpecificActions.loadAccountInfo(),
            accountSpecificActions.loadAccountAnonymousWeb(),
          ];

        case 2:
          return [
            accountSpecificActions.loadAccountInfo(),
            accountSpecificActions.loadAccountOpportunities(),
            accountSpecificActions.loadAccountSales(),
            accountSpecificActions.loadAccountTouches(),
            accountSpecificActions.loadAccountPeople(),
            accountSpecificActions.loadAccountOpptyHistory(),
            accountSpecificActions.loadAccountAnonymousWeb(),
          ];

        case 3:
          return [
            accountSpecificActions.loadAccountInfo(),
            accountSpecificActions.loadAccountOpportunities(),
            accountSpecificActions.loadAccountSales(),
            accountSpecificActions.loadAccountTouches(),
            accountSpecificActions.loadAccountPeople(),
            accountSpecificActions.loadAccountOpptyHistory(),
            accountSpecificActions.loadAccountAnonymousWeb(),
          ];
      }
    })
  ));

  public loadAccountInfo$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAccountInfo),
    concatLatestFrom(() => this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificId))),
    switchMap(([_, accountId]: [never, string]) => {
      return this.accountSpecificService.getAccountInfo$(accountId)
        .pipe(
          mergeMap((data: IAccountInfo) => [
            accountSpecificActions.loadAccountInfoSuccess({ config: data}),
            navMenusActions.SetCustomRouteLabel({
              payload: data.name
            }),
            navMenusActions.SetRouteLogo({
              payload: data.website
            }),
          ]),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'dataStudio.features.accountSpecific.errors.loadAccountSpecific';
            return of(accountSpecificActions.loadAccountInfoFailure({ message }));
          })
        );
    })
  ));

  public loadAccountSales$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAccountSales),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))
      ),
    )),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountSalesData$(filters)
        .pipe(
          mergeMap((data: IAccountSalesDataPayload) => [
            accountSpecificActions.loadAccountSalesSuccess({
              data: {
                leadTaskCount: data.lead_task_count,
                opptyTaskCount: data.oppty_task_count,
                tasks: data.tasks.map(({week_of_year, created_date, assigned_to, ...item})=> {
                  return {
                    ...item,
                    weekOfYear: week_of_year,
                    assignedTo: assigned_to,
                    createdDate: created_date
                  }
                })
              }
            }),
          ]),
          catchError((error: HttpErrorResponse) => {
            const message = error.message || 'dataStudio.features.accountSpecific.errors.loadSalesData';
            return of(accountSpecificActions.loadAccountSalesFailure({ message }));
          })
        );
    })
  ));

  public getFilters$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.getAccountFilters),
    map((action) => action.params),
    mergeMap((queryParams) => {
      const filters = this.accountSpecificService.getAccountSpecificFiltersFromParams(queryParams);
      return [
        accountSpecificActions.getSelectedDateCohort({ params: filters }),
        accountSpecificActions.setAccountFilters({ params: filters }),
      ];
    }),
  ));

  public getSelectedDateCohort$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.getSelectedDateCohort),
    map((action) => action.params),
    switchMap((filters: IAccountSpecificParams) => {
      // wait unit all date cohorts will be in the store and set date cohort
      return this.store.pipe(select(DateCohortsSelectors.getDateCohortIsLoaded)).pipe(
        filter(isLoaded => isLoaded),
        mergeMap(() => this.dateCohortService.getSelectedDateCohortFromParams$(filters))
      );
    }),
    map((dateCohort: IDateCohort) => accountSpecificActions.setSelectedDateCohort({ dateCohort }))
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      accountSpecificActions.loadAccountInfoFailure,
      accountSpecificActions.loadAccountOpportunitiesFailure,
      accountSpecificActions.loadAccountSalesFailure,
      accountSpecificActions.loadAccountTouchesFailure,
      accountSpecificActions.loadAccountPeopleFailure,
      accountSpecificActions.loadAccountAnonymousWebFailure,
      accountSpecificActions.loadAccountOpptyHistoryFailure,
      accountSpecificActions.loadAdvertisingFailure
    ),
    map((action: {message: string}) => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) }))
  ));


  public onLoadOpportunities$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAccountOpportunities),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))
      ),
    )),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountOpportunities$(filters).pipe(
        map(opportunities => accountSpecificActions.loadAccountOpportunitiesSuccess({ opportunities })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'dataStudio.features.accountSpecific.errors.loadOpportunities';
          return of(accountSpecificActions.loadAccountOpportunitiesFailure({ message }));
        })
      );
    })
  ));

  public onLoadPeople$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAccountPeople),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))
      ),
    )),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountPeople$(filters).pipe(
        map(people => accountSpecificActions.loadAccountPeopleSuccess({ people })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'dataStudio.features.accountSpecific.errors.loadPeople';
          return of(accountSpecificActions.loadAccountPeopleFailure({ message }));
        })
      );
    })
  ));

  public onDownloadAccountTable$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.downloadAccountTable),
    concatLatestFrom(() => [
      this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificActiveTab)),
      this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificInfo)),
      this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificSortedTable)),
      this.store.pipe(select(OrgConfigSelectors.getOrgCurrencySetting))
    ]),
    tap(([_, activeTab, accountInfo, tableData, currency]: [never, IAccountAttributionTab, IAccountInfo, IAccountTableData[], string]) => {
        const columns = activeTab.table.columns.map(column => {
          return {
            ...column,
            displayName: this.translateService.instant(column.displayName)
          };
        });
        columns.push({
          name: 'id',
          width: 400,
          displayName: 'Id',
          dataType: DataTypeEnum.Text,
          visible: true
        });
        const fileName = `${accountInfo.name}-${this.translateService.instant(activeTab.name)}`;
        this.downloadService.downloadCsv(fileName, tableData, columns, currency);
      }
    )
  ), { dispatch: false });

  public onLoadTouches$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAccountTouches),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))
      ),
    )),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountTouches$(filters).pipe(
        map(touches => accountSpecificActions.loadAccountTouchesSuccess( { touches })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'dataStudio.features.accountSpecific.errors.loadTouches';
          return of(accountSpecificActions.loadAccountTouchesFailure({ message }));
        })
      );
    })
  ));

  public onLoadAnonymousWeb$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAccountAnonymousWeb),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))
      ),
    )),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountAnonymousWeb$(filters).pipe(
        map(anonymousWeb => accountSpecificActions.loadAccountAnonymousWebSuccess({ anonymousWeb })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'dataStudio.features.accountSpecific.errors.loadAnonymousWeb';
          return of(accountSpecificActions.loadAccountAnonymousWebFailure({ message }));
        })
      );
    })
  ));

  public onLoadOpptyHistory$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAccountOpptyHistory),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))
      ),
    )),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountOpptyHistory$(filters).pipe(
        map(history => accountSpecificActions.loadAccountOpptyHistorySuccess({ history })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'dataStudio.features.accountSpecific.errors.loadOpptyHistory';
          return of(accountSpecificActions.loadAccountOpptyHistoryFailure({ message }));
        })
      );
    })
  ));

  public loadTradingInfo$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadTradingInfo),
    concatLatestFrom(() => this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountTrendingInfo$({
        ...filters,
        account_filter: 'all',
      }).pipe(
        map((info: IAccountTrendingInfo) => accountSpecificActions.loadTradingInfoSuccess({ info })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'dataStudio.features.accountSpecific.errors.loadTradingInfo';
          return of(accountSpecificActions.loadTradingInfoFailure({ message }));
        })
      );
    })
  ));

  public onSetRouteData$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.setRouteData),
    concatLatestFrom(() => this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificInfo))),
    mergeMap(([_, info]) => {
      return info ? [
        navMenusActions.SetCustomRouteLabel({
          payload: info.name
        }),
        navMenusActions.SetRouteLogo({
          payload: info.website
        })
      ] : [];
    }),
  ));

  public onLoadAdvertising$ = createEffect(() => this.actions$.pipe(
    ofType(accountSpecificActions.loadAdvertising),
    concatMap(action => of(action).pipe( // use concatMap to wait until dispatching this action
      withLatestFrom(
        this.store.pipe(select(AccountSpecificSelectors.getAccountSpecificFilters))
      ),
    )),
    switchMap(([_, filters]: [never, IAccountSpecificParams]) => {
      return this.accountSpecificService.getAccountAdvertising$({
        accountId: filters.accountId,
        cohort: filters.cohort,
        startDate: filters.startDate,
        endDate: filters.endDate,
        al: filters.al,
        timeUnit: filters.timeUnit,
        withSalesActivity: filters.withSalesActivity
      }).pipe(
        map(advertisingList => accountSpecificActions.loadAdvertisingSuccess({ advertisingList })),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'dataStudio.features.accountSpecific.errors.loadAdvertising';
          return of(accountSpecificActions.loadAdvertisingFailure({ message }));
        })
      );
    })
  ));
}
