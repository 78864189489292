import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TsLoadingOverlayModule } from '@terminus-lib/ui-loading-overlay';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TsTabsModule } from '@terminus-lib/ui-tabs';

import { AccountSpecificComponent } from './containers/account-specific.component';
import { AccountSpecificRoutingModule } from './account-specific-routing.module';
import * as AccountSpecificReducer from './state/account-specific.reducer';
import { AccountSpecificEffects } from './state/account-specific.effects';
import { AccountSpecificService } from './services/account-specific.service';
import { AccountSpecificSource } from './sources/account-specific.source';
import { PortalDirectiveModule } from '@ui/directives/portal';
import { TsButtonModule } from '@terminus-lib/ui-button';
import { TypedDataModule } from '@ui/pipes/typed-data';

@NgModule({
  declarations: [AccountSpecificComponent],
    imports: [
      CommonModule,
      AccountSpecificRoutingModule,
      TsLoadingOverlayModule,
      TranslateModule.forChild(),
      StoreModule.forFeature(AccountSpecificReducer.accountSpecificFeatureKey, AccountSpecificReducer.reducer),
      EffectsModule.forFeature([AccountSpecificEffects]),
      TsButtonModule,
      TsTabsModule,
      PortalDirectiveModule,
      TypedDataModule,
    ],
    providers: [
      AccountSpecificService,
      AccountSpecificSource,
    ]
})
export class AccountSpecificModule {}
