<mat-sidenav-container class="container"
                       autosize="false"
                       hasBackdrop="false"
                       [class.right-sidenav-extended]="(rightSidenavExtended$ | async) && (isUtilitiesSidenavVisible$ | async)">
  <mat-sidenav *ngIf="!greenfieldBridge.isConnected" 
               position="start"
               opened="true"
               mode="side"
               [fixedInViewport]="false">
    <tsh-app-menu (switchUser)=switchUser($event)
                  [baseHubPath]="baseHubPath"
                  [user]="userProfile$ | async"
                  [switcherContent]="switcherContent$ | async"
                  [selectedNavItem]="selectedNavItem$ | async"
                  [menu]="navMenu$ | async"
                  currentTitle="appHeader.title"
                  [zdSsoAccess]="zdSsoAccess$ | async"
                  [options]="sidenavUserMenuLinks$ | async">
    </tsh-app-menu>
  </mat-sidenav>

  <mat-sidenav-content class="content"
                       [ngClass]="{'hidden-nav': greenfieldBridge.isConnected}"
                       [class.content-with-utilities]="isUtilitiesSidenavVisible$ | async">
      <tsh-app-header [selectedRoute]="selectedRoute$ | async"
                      [selectedNavItem]="selectedNavItem$ | async"
                      [isNewLayout]="isNewLayout$ | async"
                      [lastUpdate]="(appSettings$ | async)?.dcLastUpdatedDate"
                      [specificLink]="specificLink$ | async"
                      [parent]="parent$ | async"
                      applicationTitle="appHeader.title"
                      (navigate)="navigate($event)">
      </tsh-app-header>
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
  </mat-sidenav-content>
  <mat-sidenav
    *ngIf="(isUtilitiesSidenavVisible$ | async) && (hubToken$ | async)"
    class="right-sidenav"
    disableClose
    opened="true"
    position="end"
    fixedTopGap="64"
    fixedBottomGap="0"
    mode="over"
    [fixedInViewport]="false">
    <tsh-app-sidenav></tsh-app-sidenav>
  </mat-sidenav>
</mat-sidenav-container>
