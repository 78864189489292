import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MetaReducer, RuntimeChecks, StoreModule } from '@ngrx/store';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppMenuModule } from '@ui/components/app-menu';
import { DATA_STUDIO_SHARED_ROUTES, hubTokenName, LANGUAGES, MEASUREMENT_STUDIO_SHARED_ROUTES } from '@shared/constants';
import { sharedEffects, sharedReducers } from '@shared-store';
import { JwtTokenManagementModule } from '@terminus-lib/fe-jwt';
import { ClaimMap } from '@hub-token';
import { RollbarErrorHandler, rollbarFactory, RollbarJS } from '@shared/rollbar';
import { HubRouterSerializer } from '@hub-router';
import { APP_ROUTES } from '@app-routes';
import { NotificationModule } from '@ui/components/notification';
import { CamelCaseInterceptor, ContinuationTokenOnlyInterceptor, EXCLUDED_URLS_FOR_INTERCEPTOR, HttpBaseInterceptor } from '@shared/interceptors';
import { NAV_MENU } from '@nav-menus';
import { DATA_STUDIO_NAV, DATA_STUDIO_ROUTES, DATA_STUDIO_SETTINGS_ROUTE_ITEMS } from '@data-studio/constants';
import { AppRoutingModule } from './app-routing.module';
import { TypedDataPipe } from '@ui/pipes/typed-data';
import { AppHeaderModule } from '@ui/components/app-header';
import { PortalDirectiveModule } from '@ui/directives/portal';
import { SETTINGS_ROUTE_ITEMS } from '@shared/data-access/shared-settings';
import { SidenavUtilitiesModule } from '@shared/features/sidenav-utilities';
import { LocalStorageModule } from '@shared/data-access/local-storage';
import { TileModalModule } from '@shared/features/tile-modal';
import { TileDataService } from '@data-studio/features/tiles-data';
import { userAuthenticateToken } from '@shared/data-access/user-common';
import { UserService } from '@user/*';
import { DemoInterceptor } from '@shared/data-access/demoable-interceptor';
import { demoConfigMetaReducer } from '@shared/data-access/demo-config-state';
import { APP_IDENTITY } from '@shared/data-access/app-identity';
import { AccountSpecificSourceURLs } from '@data-studio/features/account-specific';
import { RouteTypeEnum } from '@shared/enums';
import { AppContainerComponent } from './containers/app/app-container.component';
import { SplitioModule, TreatmentTokenModule } from '@shared/splitio';
import { ENVIRONMENT } from '@shared/data-access/environment';
import { GreenfieldBridge } from '@util/greenfield-bridge';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/shared/i18n/', suffix: '.json?tstamp=' + Date.now() },
    { prefix: './assets/i18n/', suffix: '.json?tstamp=' + Date.now() },
  ]);
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.warn(`Missing Translation from ${params.key}`)
    return params.key;
  }
}

const runtimeChecks: RuntimeChecks = {
  strictStateImmutability: false,
  strictActionImmutability: false,
  strictStateSerializability: false,
  strictActionSerializability: false,
  strictActionWithinNgZone: false,
};

const metaReducers: MetaReducer[] = [
  demoConfigMetaReducer,
];

@NgModule({
  declarations: [
    AppComponent,
    AppContainerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SplitioModule,
    TreatmentTokenModule,
    TranslateModule.forRoot({
      defaultLanguage: LANGUAGES[0],
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    JwtTokenManagementModule.forRoot<ClaimMap>({
      initialTokenName: hubTokenName
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot({
      ...sharedReducers
    }, { runtimeChecks, metaReducers }),
    EffectsModule.forRoot([
      ...sharedEffects,
    ]),
    StoreDevtoolsModule.instrument({
      name: 'Data Studio',
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    MatSidenavModule,
    AppMenuModule,
    NotificationModule,
    AppHeaderModule,
    SidenavUtilitiesModule,
    PortalDirectiveModule.forRoot(),
    LocalStorageModule.forRoot(),
    TileModalModule.forRoot(TileDataService),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: HubRouterSerializer
    },
    {
      provide: ErrorHandler,
      useClass: RollbarErrorHandler
    },
    {
      provide: RollbarJS,
      useFactory: rollbarFactory
    },
    {
      provide: userAuthenticateToken,
      useClass: UserService,
    },
    // for camel case interceptor (only data studio urls)
    {
      provide: EXCLUDED_URLS_FOR_INTERCEPTOR,
      useValue: [
        AccountSpecificSourceURLs.GetSalesData
      ]
    },
    {
      provide: APP_IDENTITY,
      useValue: 'data'
    },
    {
      provide: APP_ROUTES,
      useValue: [
        ...DATA_STUDIO_ROUTES,
        ...DATA_STUDIO_SHARED_ROUTES,
        ...MEASUREMENT_STUDIO_SHARED_ROUTES.map(route => ({
          ...route,
          externalUrlKey: 'TERMINUS_HUB_URL',
          routeType: RouteTypeEnum.ExternalLink
        })),
      ],
    },
    {
      provide: NAV_MENU,
      useValue: DATA_STUDIO_NAV
    },
    {
      provide: SETTINGS_ROUTE_ITEMS,
      useValue: DATA_STUDIO_SETTINGS_ROUTE_ITEMS,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DemoInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CamelCaseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBaseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContinuationTokenOnlyInterceptor,
      multi: true
    },
    {
      provide: GreenfieldBridge,
      useValue: new GreenfieldBridge()
    },
    TypedDataPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(ENVIRONMENT) readonly env: { DS_GREENFIELD_URL: string, GREENFIELD_REDIRECT: boolean },
    greenfieldBridge: GreenfieldBridge,
    router: Router
  ) {
    greenfieldBridge.init(env.DS_GREENFIELD_URL, env.GREENFIELD_REDIRECT, (url) => router.navigateByUrl(url));
  }
}
