import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { TsSortState } from '@terminus-lib/ui-sort';

import { IAccountInfo } from '../interfaces/account-info.interface';
import { IAccountSpecificParams } from '../interfaces/account-params.interface';
import { IDateCohort, IEditTableColumn, ILabelValue, IReportColumn } from '@shared/interfaces';
import { IAccountOpportunity } from '../interfaces/account-opportunity.interface';
import { IAccountSalesData } from '../interfaces/account-sales-data.interface';
import { IAccountTouches } from '../interfaces/account-touches.interface';
import { IAccountPeople, IAccountPerson } from '../interfaces/account-people.interface';
import { IAccountAnonymousWeb } from '../interfaces/account-anonymous_web.interface';
import { IAccountOpptyHistory } from '../interfaces/account-oppty-history.interface';
import { AccountSpecificTab } from '../data/account-tabs.data';
import { IAccountTrendingInfo } from '../interfaces/account-trending-info.interface';
import { ActivityType } from '../enums/activity-type.enum';
import { AccountAttributionTabs } from '../enums/account-attribution-tabs.enum';
import { IAccountAdvertising } from '../interfaces/account-advertising.interface';

export enum AccountSpecificActionsTypes {
  LoadAccountInfo = '[Account Specific] Load Account Info',
  LoadAccountInfoSuccess = '[Account Specific] Load Account Info Success',
  LoadAccountInfoFailure = '[Account Specific] Load Account Info Failure',

  LoadAccountOpportunities = '[Account Specific] Load Account Opportunities',
  LoadAccountOpportunitiesSuccess = '[Account Specific] Load Account Opportunities Success',
  LoadAccountOpportunitiesFailure = '[Account Specific] Load Account Opportunities Failure',

  LoadAccountSales = '[Account Specific] Load Account Sales',
  LoadAccountSalesSuccess = '[Account Specific] Load Account Sales Success',
  LoadAccountSalesFailure = '[Account Specific] Load Account Sales Failure',

  LoadAccountTouches = '[Account Specific] Load Account Touches',
  LoadAccountTouchesSuccess = '[Account Specific] Load Account Touches Success',
  LoadAccountTouchesFailure = '[Account Specific] Load Account Touches Failure',

  LoadAccountPeople = '[Account Specific] Load Account People',
  LoadAccountPeopleSuccess = '[Account Specific] Load Account People Success',
  LoadAccountPeopleFailure = '[Account Specific] Load Account People Failure',

  LoadAccountAnonymousWeb = '[Account Specific] Load Account Anonymous Web',
  LoadAccountAnonymousWebSuccess = '[Account Specific] Load Account Anonymous Web Success',
  LoadAccountAnonymousWebFailure = '[Account Specific] Load Account Anonymous Web Failure',

  LoadAccountOpptyHistory = '[Account Specific] Load Account Oppty History',
  LoadAccountOpptyHistorySuccess = '[Account Specific] Load Account Oppty History Success',
  LoadAccountOpptyHistoryFailure = '[Account Specific] Load Account Oppty History Failure',

  LoadTradingInfo = '[Account Specific] Load Trading Info',
  LoadTradingInfoSuccess = '[Account Specific] Load Trading Info Success',
  LoadTradingInfoFailure = '[Account Specific] Load Trading Info Failure',

  LoadAdvertising = '[Account Specific] Load Advertising',
  LoadAdvertisingSuccess = '[Account Specific] Load Advertising Success',
  LoadAdvertisingFailure = '[Account Specific] Load Advertising Failure',

  SetAccountId = '[Account Specific] Set Account Id',
  SetAccountActiveTab = '[Account Specific] Set Account Active Tab',
  SetAccountTabPage = '[Account Specific] Set Account Tab Page',
  DownloadAccountTable = '[Account Specific] Download Account Table',
  ToggleAccountTabColumn = '[Account Specific] Toggle Account Tab Column',
  SetAccountTabSearch = '[Account Specific] Set Account Tab Search',
  SetAccountTabSort = '[Account Specific] Set Account Tab Sort',
  SetAccountFilters = '[Account Specific] Set Account Filters',
  GetAccountFilters = '[Account Specific] Get Account Filters',
  GetSelectedDateCohort = '[Account Specific] Get Selected Date cohort',
  SetSelectedDateCohort = '[Account Specific] Set Selected Date cohort',

  SetAccountSortState = '[Account Specific] Set Account Sort State',
  SetAccountPage = '[Account Specific] Set Account Page',
  ToggleExpandPerson = '[Account Specific] Toggle Expand Person',
  SetAccountExpandedPage = '[Account Specific] Set Account Expanded Page',
  SetAccountExpandedSort = '[Account Specific] Set Account Expanded Sort State',
  GetAccountPeopleFilters = '[Account Specific] Get Account People Filters',
  SetAccountPeopleFilters = '[Account Specific] Set Account People Filters',
  SetAccountReportColumns = '[Account Specific] Set Account Report Columns',
  SetSelectedAccountType = '[Account Specific] Set Selected Account Type',
  SetRouteData = '[Account Specific] Set Route Data',
  GetSelectedTab = '[Account Specific] Get Selected Tab',
  SetSelectedTab = '[Account Specific] Set Selected Tab',
}

const loadAccountInfo = createAction(
  AccountSpecificActionsTypes.LoadAccountInfo
);

const loadAccountInfoSuccess = createAction(
  AccountSpecificActionsTypes.LoadAccountInfoSuccess,
  props<{ config: IAccountInfo }>()
);

const loadAccountInfoFailure = createAction(
  AccountSpecificActionsTypes.LoadAccountInfoFailure,
  props<{ message: string }>()
);

const loadAccountSales = createAction(
  AccountSpecificActionsTypes.LoadAccountSales
);

const loadAccountSalesSuccess = createAction(
  AccountSpecificActionsTypes.LoadAccountSalesSuccess,
  props<{ data: IAccountSalesData }>()
);

const loadAccountSalesFailure = createAction(
  AccountSpecificActionsTypes.LoadAccountSalesFailure,
  props<{ message: string }>()
);

const setAccountFilters = createAction(
  AccountSpecificActionsTypes.SetAccountFilters,
  props<{ params: IAccountSpecificParams }>()
);

const getSelectedDateCohort = createAction(
  AccountSpecificActionsTypes.GetSelectedDateCohort,
  props<{ params: IAccountSpecificParams }>()
);

const setSelectedDateCohort = createAction(
  AccountSpecificActionsTypes.SetSelectedDateCohort,
  props<{ dateCohort: IDateCohort }>()
);

const setAccountActiveTab = createAction(
  AccountSpecificActionsTypes.SetAccountActiveTab,
  props<{ tab: AccountAttributionTabs }>()
);

const setAccountTabPage = createAction(
  AccountSpecificActionsTypes.SetAccountTabPage,
  props<{ payload:  { key: AccountAttributionTabs, page: number } }>()
);

const toggleAccountTabColumn = createAction(
  AccountSpecificActionsTypes.ToggleAccountTabColumn,
  props<{ payload:  { key: AccountAttributionTabs, column: IEditTableColumn } }>()
);

const downloadAccountTable = createAction(
  AccountSpecificActionsTypes.DownloadAccountTable,
);

const setAccountTabSearch = createAction(
  AccountSpecificActionsTypes.SetAccountTabSearch,
  props<{ searchQuery:  string }>()
);

const setAccountTabSort = createAction(
  AccountSpecificActionsTypes.SetAccountTabSort,
  props<{ sortState:  TsSortState }>()
);

const getAccountFilters = createAction(
  AccountSpecificActionsTypes.GetAccountFilters,
  props<{ params:  Params }>()
);

const setAccountSortState = createAction(
  AccountSpecificActionsTypes.SetAccountSortState,
  props<{ sortState:  TsSortState }>()
);

const setAccountPage = createAction(
  AccountSpecificActionsTypes.SetAccountPage,
  props<{ pageNumber:  number }>()
);

const loadAccountOpportunities = createAction(
  AccountSpecificActionsTypes.LoadAccountOpportunities
);

const loadAccountOpportunitiesSuccess = createAction(
  AccountSpecificActionsTypes.LoadAccountOpportunitiesSuccess,
  props<{ opportunities:  IAccountOpportunity[] }>()
);

const loadAccountOpportunitiesFailure = createAction(
  AccountSpecificActionsTypes.LoadAccountOpportunitiesFailure,
  props<{ message: string }>()
);

const loadAccountTouches = createAction(
  AccountSpecificActionsTypes.LoadAccountTouches
);

const loadAccountTouchesSuccess = createAction(
  AccountSpecificActionsTypes.LoadAccountTouchesSuccess,
  props<{ touches: IAccountTouches }>()
);

const loadAccountTouchesFailure = createAction(
  AccountSpecificActionsTypes.LoadAccountTouchesFailure,
  props<{ message: string }>()
);

const loadAccountPeople = createAction(
  AccountSpecificActionsTypes.LoadAccountPeople
);

const loadAccountPeopleSuccess = createAction(
  AccountSpecificActionsTypes.LoadAccountPeopleSuccess,
  props<{ people: IAccountPeople }>()
);

const loadAccountPeopleFailure = createAction(
  AccountSpecificActionsTypes.LoadAccountPeopleFailure,
  props<{ message: string }>()
);

const loadAccountAnonymousWeb = createAction(
  AccountSpecificActionsTypes.LoadAccountAnonymousWeb
);

const loadAccountAnonymousWebSuccess = createAction(
  AccountSpecificActionsTypes.LoadAccountAnonymousWebSuccess,
  props<{ anonymousWeb: IAccountAnonymousWeb }>()
);

const loadAccountAnonymousWebFailure = createAction(
  AccountSpecificActionsTypes.LoadAccountAnonymousWebFailure,
  props<{ message: string }>()
);

const loadAccountOpptyHistory = createAction(
  AccountSpecificActionsTypes.LoadAccountOpptyHistory,
);

const loadAccountOpptyHistorySuccess = createAction(
  AccountSpecificActionsTypes.LoadAccountOpptyHistorySuccess,
  props<{ history: IAccountOpptyHistory }>()
);

const loadAccountOpptyHistoryFailure = createAction(
  AccountSpecificActionsTypes.LoadAccountOpptyHistoryFailure,
  props<{ message: string }>()
);

const toggleExpandPerson = createAction(
  AccountSpecificActionsTypes.ToggleExpandPerson,
  props<{ person: IAccountPerson }>()
);

const setAccountExpandedPage = createAction(
  AccountSpecificActionsTypes.SetAccountExpandedPage,
  props<{ expandedPage: { page: number; key: string; } }>()
);

const setAccountExpandedSort = createAction(
  AccountSpecificActionsTypes.SetAccountExpandedSort,
  props<{ expandedSort: { state: TsSortState; key: string; } }>()
);

const getAccountPeopleFilters = createAction(
  AccountSpecificActionsTypes.GetAccountPeopleFilters,
  props<{ params: { state: TsSortState; key: string; } }>()
);

const setAccountPeopleFilters = createAction(
  AccountSpecificActionsTypes.SetAccountPeopleFilters,
  props<{ params: IAccountSpecificParams }>()
);

const setAccountReportColumns = createAction(
  AccountSpecificActionsTypes.SetAccountReportColumns,
  props<{ columns: IReportColumn[] }>()
);

const loadTradingInfo = createAction(
  AccountSpecificActionsTypes.LoadTradingInfo
);

const loadTradingInfoSuccess = createAction(
  AccountSpecificActionsTypes.LoadTradingInfoSuccess,
  props<{ info: IAccountTrendingInfo }>()
);

const loadTradingInfoFailure = createAction(
  AccountSpecificActionsTypes.LoadTradingInfoFailure,
  props<{ message: string }>()
);

const setSelectedAccountType = createAction(
  AccountSpecificActionsTypes.SetSelectedAccountType,
  props<{ labels: ILabelValue<ActivityType> }>()
);

const setRouteData = createAction(
  AccountSpecificActionsTypes.SetRouteData
);

const getSelectedTab = createAction(
  AccountSpecificActionsTypes.GetSelectedTab
);

const setSelectedTab = createAction(
  AccountSpecificActionsTypes.SetSelectedTab,
  props<{ tab: AccountSpecificTab }>()
);

const loadAdvertising = createAction(
  AccountSpecificActionsTypes.LoadAdvertising
);

const loadAdvertisingSuccess = createAction(
  AccountSpecificActionsTypes.LoadAdvertisingSuccess,
  props<{ advertisingList: IAccountAdvertising[] }>()
);

const loadAdvertisingFailure = createAction(
  AccountSpecificActionsTypes.LoadAdvertisingFailure,
  props<{ message: string }>()
);

export const accountSpecificActions = {
  loadAccountInfo,
  loadAccountInfoSuccess,
  loadAccountInfoFailure,
  loadAccountOpportunities,
  loadAccountOpportunitiesSuccess,
  loadAccountOpportunitiesFailure,
  loadAccountSales,
  loadAccountSalesSuccess,
  loadAccountSalesFailure,
  setAccountFilters,
  getAccountFilters,
  getSelectedDateCohort,
  setSelectedDateCohort,
  setAccountActiveTab,
  setAccountTabPage,
  downloadAccountTable,
  toggleAccountTabColumn,
  setAccountTabSearch,
  setAccountTabSort,
  loadAccountTouches,
  loadAccountTouchesSuccess,
  loadAccountTouchesFailure,
  loadAccountPeople,
  loadAccountPeopleSuccess,
  loadAccountPeopleFailure,
  loadAccountAnonymousWeb,
  loadAccountAnonymousWebSuccess,
  loadAccountAnonymousWebFailure,
  loadAccountOpptyHistory,
  loadAccountOpptyHistorySuccess,
  loadAccountOpptyHistoryFailure,
  setAccountSortState,
  setAccountPage,
  toggleExpandPerson,
  setAccountExpandedPage,
  setAccountExpandedSort,
  getAccountPeopleFilters,
  setAccountPeopleFilters,
  setAccountReportColumns,
  loadTradingInfo,
  loadTradingInfoSuccess,
  loadTradingInfoFailure,
  setSelectedAccountType,
  setRouteData,
  getSelectedTab,
  setSelectedTab,
  loadAdvertising,
  loadAdvertisingSuccess,
  loadAdvertisingFailure
}
