export enum AccountSpecificTab {
  Overview = 'attribution',
  Details = 'trending',
  People = 'people',
  Activities = 'activities',
}


export const ACCOUNT_TABS = [
  {
      name: 'overview',
      link: AccountSpecificTab.Overview
  }, {
      name: 'details',
      link: AccountSpecificTab.Details
  }, {
    name: 'people',
    link: AccountSpecificTab.People
  }, {
    name: 'activities',
    link: AccountSpecificTab.Activities
  }
];
