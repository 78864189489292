import { Action, createReducer, on } from '@ngrx/store';

import { accountSpecificActions } from './account-specific.actions';
import { IAccountInfo } from '../interfaces/account-info.interface';
import { IAccountSpecificParams } from '../interfaces/account-params.interface';
import { IDateCohort, ILabelValue, IReportColumn, IReportTable } from '@shared/interfaces';
import { IAccountOpportunity } from '../interfaces/account-opportunity.interface';
import { IAccountSalesData } from '../interfaces/account-sales-data.interface';
import { IAccountTouches } from '../interfaces/account-touches.interface';
import { IAccountPeople } from '../interfaces/account-people.interface';
import { IAccountAnonymousWeb } from '../interfaces/account-anonymous_web.interface';
import { IAccountOpptyHistory } from '../interfaces/account-oppty-history.interface';
import { ACCOUNT_TABS } from '../data/account-tabs.data';
import { IAccountAttributionTab } from '../interfaces/account-tabs.interface';
import { calculateAgregation } from '../account-specific.helper';
import { SortDirections } from '@shared/enums';
import {
  ADVERTISING_COLUMNS,
  CAMPAIGN_RESPONSES_COLUMNS,
  OPPORTUNITIES_COLUMNS,
  ORPHAN_LEADS_COLUMNS,
  PEOPLE_COLUMNS,
  SALES_ACTIVITY_COLUMNS
} from '../data/account-attribution-columns.data';
import { updateColumnsVisibility } from '@util/store';
import { PER_PAGE } from '@shared/constants';
import { IAccountTrendingInfo } from '../interfaces/account-trending-info.interface';
import { IAccountActivity } from '../interfaces/account-activity.interface';
import { ActivityType } from '../enums/activity-type.enum';
import { AccountTypeOptions } from '../data/account-activity-type.data';
import { AccountAttributionTabs } from '../enums/account-attribution-tabs.enum';
import { IAccountAdvertising } from '../interfaces/account-advertising.interface';

const getInitReportTable = (columns: IReportColumn[]) => ({
  sortState: {
    active: 'activitiesCounts',
    direction: SortDirections.Desc
  },
  columns,
  pagination: {
    page: 1,
    perPage: PER_PAGE,
  },
  searchQuery: '',
});

export const accountSpecificFeatureKey = 'accountSpecific';

export interface AccountSpecificState {
  isLoading: number;
  accountInfo: IAccountInfo;
  filters: IAccountSpecificParams;
  accountAttributionTabs: {
    [AccountAttributionTabs.Opportunities]: IAccountAttributionTab
    [AccountAttributionTabs.People]: IAccountAttributionTab
    [AccountAttributionTabs.OrphanLeads]: IAccountAttributionTab
    [AccountAttributionTabs.CampaignResponses]: IAccountAttributionTab
    [AccountAttributionTabs.SalesActivities]: IAccountAttributionTab
    [AccountAttributionTabs.Advertising]: IAccountAttributionTab
  };
  accountAttributionActiveTab: AccountAttributionTabs;
  selectedDateCohort: IDateCohort | null;
  accountOpportunities: IAccountOpportunity[];
  accountSalesData: IAccountSalesData;
  accountTouches: IAccountTouches;
  accountPeople: IAccountPeople;
  accountAnonymousWeb: IAccountAnonymousWeb;
  accountOpptyHistory: IAccountOpptyHistory;
  accountAdvertising: IAccountAdvertising[];
  expandedReport: Record<string, { data: IAccountActivity[]; reportTable: IReportTable; }>;
  expandedRowState: Record<string, boolean>;
  reportTable: IReportTable;
  accountTradingInfo: IAccountTrendingInfo;
  selectedAccountTypeOption: ILabelValue<ActivityType>;
  selectedOpportunityOption: ILabelValue;
  selectedTab: number;
}

export const initialState: AccountSpecificState = {
  isLoading: 0,
  accountInfo: null,
  accountAttributionActiveTab: AccountAttributionTabs.Opportunities,
  accountAttributionTabs: {
    [AccountAttributionTabs.Opportunities]: {
      name: 'dataStudio.features.accountSpecific.tabs.opportunities',
      count: 0,
      table: {
        searchQuery: '',
        sortState: {
          active: '',
          direction: SortDirections.Asc
        },
        pagination: {
          page: 1,
            perPage: PER_PAGE
        },
        columns: OPPORTUNITIES_COLUMNS
      },
    },
    [AccountAttributionTabs.People]: {
      name: 'dataStudio.features.accountSpecific.tabs.people',
      count: 0,
      table: {
        searchQuery: '',
        sortState: {
          active: '',
          direction: SortDirections.Asc
        },
        pagination: {
          page: 1,
          perPage: PER_PAGE
        },
        columns: PEOPLE_COLUMNS
      },
    },
    [AccountAttributionTabs.OrphanLeads]: {
      name: 'dataStudio.features.accountSpecific.tabs.orphanLeads',
      count: 0,
      table: {
        searchQuery: '',
        sortState: {
          active: '',
          direction: SortDirections.Asc
        },
        pagination: {
          page: 1,
          perPage: PER_PAGE
        },
        columns: ORPHAN_LEADS_COLUMNS
      },
    },
    [AccountAttributionTabs.CampaignResponses]: {
      name: 'dataStudio.features.accountSpecific.tabs.campaignResponses',
      count: 0,
      table: {
        searchQuery: '',
        sortState: {
          active: 'activityDate',
          direction: SortDirections.Desc
        },
        pagination: {
          page: 1,
          perPage: PER_PAGE
        },
        columns: CAMPAIGN_RESPONSES_COLUMNS
      },
    },
    [AccountAttributionTabs.SalesActivities]: {
      name: 'dataStudio.features.accountSpecific.tabs.salesActivities',
      count: 0,
      table: {
        searchQuery: '',
        sortState: {
          active: 'createdDate',
          direction: SortDirections.Desc
        },
        pagination: {
          page: 1,
          perPage: PER_PAGE
        },
        columns: SALES_ACTIVITY_COLUMNS
      },
    },
    [AccountAttributionTabs.Advertising]: {
      name: 'dataStudio.features.accountSpecific.tabs.advertising',
      count: 0,
      table: {
        searchQuery: '',
        sortState: {
          active: '',
          direction: SortDirections.Asc
        },
        pagination: {
          page: 1,
          perPage: PER_PAGE
        },
        columns: ADVERTISING_COLUMNS
      },
    },
  },
  filters: {
    accountId: '',
    cohort: null,
    endDate: null,
    startDate: null,
    timeUnit: null,
    withSalesActivity: true
  },
  selectedDateCohort: null,
  accountOpportunities: null,
  accountSalesData: null,
  accountTouches: null,
  accountPeople: null,
  accountAnonymousWeb: null,
  accountOpptyHistory: null,
  accountAdvertising: null,
  expandedReport: {},
  expandedRowState: {},
  reportTable: getInitReportTable([]),
  accountTradingInfo: null,
  selectedAccountTypeOption: AccountTypeOptions.find(option => option.value === ActivityType.All),
  selectedOpportunityOption: { label: 'None', value: null },
  selectedTab: 0,
};

const accountSpecificReducer = createReducer(
  initialState,
  on(
    accountSpecificActions.loadAccountInfo,
    accountSpecificActions.loadAccountOpportunities,
    accountSpecificActions.loadAccountSales,
    accountSpecificActions.loadAccountTouches,
    accountSpecificActions.loadAccountPeople,
    accountSpecificActions.loadAccountAnonymousWeb,
    accountSpecificActions.loadAccountOpptyHistory,
    accountSpecificActions.loadTradingInfo,
    accountSpecificActions.loadAdvertising,
  state => ({
    ...state,
    isLoading: (state.isLoading << 1) + 1
  })),

  on(accountSpecificActions.loadAccountInfoSuccess, (state, action) => ({
    ...state,
    isLoading: state.isLoading >> 1,
    accountInfo: action.config
  })),

  on(
    accountSpecificActions.loadAccountInfoFailure,
    accountSpecificActions.loadAccountOpportunitiesFailure,
    accountSpecificActions.loadAccountSalesFailure,
    accountSpecificActions.loadAccountTouchesFailure,
    accountSpecificActions.loadAccountPeopleFailure,
    accountSpecificActions.loadAccountAnonymousWebFailure,
    accountSpecificActions.loadAccountOpptyHistoryFailure,
    accountSpecificActions.loadTradingInfoFailure,
    accountSpecificActions.loadAdvertisingFailure,
  state => ({
    ...state,
    isLoading: state.isLoading >> 1,
  })),

  on(
    accountSpecificActions.setAccountFilters,
    accountSpecificActions.setAccountPeopleFilters,
    (state, action) => ({
    ...state,
    filters: action.params,
  })),

  on(accountSpecificActions.setSelectedDateCohort,
    (state, action) => ({
      ...state,
      selectedDateCohort: action.dateCohort,
  })),

  on(accountSpecificActions.setAccountActiveTab,
    (state, action) => ({
      ...state,
      accountAttributionActiveTab: action.tab,
  })),

  on(accountSpecificActions.setAccountTabSearch,
    (state, action) => ({
      ...state,
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [state.accountAttributionActiveTab]: {
          ...state.accountAttributionTabs[state.accountAttributionActiveTab],
          table: {
            ...state.accountAttributionTabs[state.accountAttributionActiveTab].table,
            searchQuery: action.searchQuery,
            pagination: {
              page: 1,
              perPage: PER_PAGE
            }
          }
        }
      }
  })),

  on(accountSpecificActions.setAccountTabPage,
    (state, action) => ({
      ...state,
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [action.payload.key]: {
          ...state.accountAttributionTabs[action.payload.key],
          table: {
            ...state.accountAttributionTabs[action.payload.key].table,
            pagination: {
              page: action.payload.page,
              perPage: PER_PAGE
            }
          }
        }
      }
  })),

  on(accountSpecificActions.setAccountTabSort,
    (state, action) => ({
      ...state,
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [state.accountAttributionActiveTab]: {
          ...state.accountAttributionTabs[state.accountAttributionActiveTab],
          table: {
            ...state.accountAttributionTabs[state.accountAttributionActiveTab].table,
            sortState: action.sortState
          }
        }
      }
  })),

  on(accountSpecificActions.loadAccountOpportunitiesSuccess,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading >> 1,
      accountOpportunities: action.opportunities,
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [AccountAttributionTabs.Opportunities]: {
          ...state.accountAttributionTabs[AccountAttributionTabs.Opportunities],
          count: action.opportunities.length
        }
      }
  })),

  on(accountSpecificActions.loadAccountSalesSuccess,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading >> 1,
      accountSalesData: action.data,
      accountPeople: state.accountPeople && {
        orphanLeads: state.accountPeople.orphanLeads,
        contacts: calculateAgregation(state.accountPeople?.contacts || [], state.accountTouches?.activityList, action.data.tasks)
      },
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [AccountAttributionTabs.SalesActivities]: {
          ...state.accountAttributionTabs[AccountAttributionTabs.SalesActivities],
          count: action.data.tasks.length
        }
      }
  })),

  on(accountSpecificActions.toggleAccountTabColumn,
    (state, action) => ({
      ...state,
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [action.payload.key]: {
          ...state.accountAttributionTabs[action.payload.key],
          table: {
            ...state.accountAttributionTabs[action.payload.key].table,
            columns:  updateColumnsVisibility(state.accountAttributionTabs[action.payload.key].table.columns, action.payload.column)
          }
        }
      }
  })),

  on(accountSpecificActions.loadAccountTouchesSuccess,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading >> 1,
      accountTouches: action.touches,
      accountPeople: state.accountPeople && {
        orphanLeads: state.accountPeople.orphanLeads,
        contacts: calculateAgregation(
          state.accountPeople?.contacts || [],
          action.touches.activityList,
          state.accountSalesData?.tasks || []
        )
      },
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [AccountAttributionTabs.CampaignResponses]: {
          ...state.accountAttributionTabs[AccountAttributionTabs.CampaignResponses],
          count: action.touches.activityList.length
        }
      }
  })),

  on(accountSpecificActions.loadAccountPeopleSuccess,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading >> 1,
      accountPeople: {
        orphanLeads: action.people.orphanLeads,
        contacts: calculateAgregation(
          action.people.contacts,
          state.accountTouches?.activityList || [],
          state.accountSalesData?.tasks || []
        )
      },
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [AccountAttributionTabs.People]: {
          ...state.accountAttributionTabs[AccountAttributionTabs.People],
          count: action.people.contacts.length
        },
        [AccountAttributionTabs.OrphanLeads]: {
          ...state.accountAttributionTabs[AccountAttributionTabs.OrphanLeads],
          count: action.people.orphanLeads.length
        }
      }
  })),

  on(accountSpecificActions.loadAccountAnonymousWebSuccess,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading >> 1,
      accountAnonymousWeb: action.anonymousWeb,
  })),

  on(accountSpecificActions.loadAccountOpptyHistorySuccess,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading >> 1,
      accountOpptyHistory: action.history,
  })),

  on(accountSpecificActions.setAccountSortState,
    (state, action) => ({
      ...state,
      reportTable: {
        ...state.reportTable,
        sortState: action.sortState,
        pagination: {
          page: 1,
          perPage: state.reportTable.pagination.perPage,
        },
      }
  })),

  on(accountSpecificActions.setAccountPage,
    (state, action) => ({
      ...state,
      reportTable: {
        ...state.reportTable,
        pagination: {
          page: action.pageNumber,
          perPage: state.reportTable.pagination.perPage,
        },
      }
  })),

  on(accountSpecificActions.toggleExpandPerson,
    (state, action) => ({
      ...state,
      expandedRowState: {
        ...state.expandedRowState,
        [action.person.id]: !state.expandedRowState[action.person.id]
      },
      expandedReport: {
        ...state.expandedReport,
        [action.person.id]: state.expandedReport[action.person.id] ||
        {
          data: action.person.activityList,
          reportTable: {
            sortState: {
              active: '',
              direction: SortDirections.Asc
            },
            pagination: {
              page: 1,
              perPage: 5,
            }
          }
        }
      }
  })),

  on(accountSpecificActions.setAccountExpandedPage,
    (state, action) => ({
      ...state,
      expandedReport: {
        ...state.expandedReport,
        [action.expandedPage.key]: {
          ...state.expandedReport[action.expandedPage.key],
          reportTable: {
            ...state.expandedReport[action.expandedPage.key].reportTable,
            pagination: {
              ...state.expandedReport[action.expandedPage.key].reportTable.pagination,
              page: action.expandedPage.page,
            }
          }
        }
      }
  })),

  on(accountSpecificActions.setAccountExpandedSort,
    (state, action) => ({
      ...state,
      expandedReport: {
        ...state.expandedReport,
        [action.expandedSort.key]: {
          ...state.expandedReport[action.expandedSort.key],
          reportTable: {
            ...state.expandedReport[action.expandedSort.key].reportTable,
            sortState: action.expandedSort.state,
          }
        }
      }
  })),

  on(accountSpecificActions.setAccountReportColumns,
    (state, action) => ({
      ...state,
      reportTable: getInitReportTable(action.columns),
  })),

  on(accountSpecificActions.loadTradingInfoSuccess,
    (state, action) => ({
      ...state,
      isLoading: state.isLoading >> 1,
      accountTradingInfo: action.info,
  })),

  on(accountSpecificActions.setSelectedAccountType,
    (state, action) => ({
      ...state,
      selectedAccountTypeOption: action.labels,
      reportTable: {
        ...state.reportTable,
        pagination: {
          page: 1,
          perPage: state.reportTable.pagination.perPage,
        },
      }
  })),

  on(accountSpecificActions.setSelectedTab,
    (state, action) => ({
      ...state,
      selectedTab: ACCOUNT_TABS.findIndex(tab => tab.link === action.tab)
  })),

  on(accountSpecificActions.loadAdvertisingSuccess,
    (state, action) => ({
      ...state,
      accountAdvertising: action.advertisingList,
      accountAttributionTabs: {
        ...state.accountAttributionTabs,
        [AccountAttributionTabs.Advertising]: {
          ...state.accountAttributionTabs[AccountAttributionTabs.Advertising],
          count: action.advertisingList.length
        }
      },
      isLoading: state.isLoading >> 1,
  })),
)

export function reducer(state: AccountSpecificState | undefined, action: Action) {
  return accountSpecificReducer(state, action);
}

