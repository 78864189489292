import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { TsTabChangeEvent } from '@terminus-lib/ui-tabs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import * as AccountSpecificSelectors from '../state/account-specific.selectors';
import { accountSpecificActions } from '../state/account-specific.actions';
import * as OrgConfigSelectors from '@org-config';
import { ACCOUNT_INFO_DATA } from '../data/account-info.data';
import { ACCOUNT_TABS } from '../data/account-tabs.data';
import { AccountSpecificState } from '../state/account-specific.reducer';
import { Subject } from 'rxjs';
import { PortalTargets } from '@shared/enums';
import { TsWindowService } from '@terminus-lib/fe-utilities';
import * as UserSelectors from '@user/user.selectors';

@Component({
  selector: 'ds-account-specific',
  templateUrl: './account-specific.component.html',
  styleUrls: ['./account-specific.component.scss']
})
export class AccountSpecificComponent implements OnDestroy {
  public isLoading$ = this.store.select(AccountSpecificSelectors.getAccountSpecificLoading);
  public accountInfo$ = this.store.select(AccountSpecificSelectors.getAccountSpecificInfoWithTotals);
  public selectedTab$ = this.store.select(AccountSpecificSelectors.getAccountSelectedTab).pipe(
    // need for apply tab selection changing by  View All link  click
    tap(
      /* istanbul ignore next */
      () => setTimeout(() => this.cd.markForCheck())
    )
  );
  public crmUrl$ = this.store.select(OrgConfigSelectors.getCrmUrlForAccount);
  public filters$ = this.store.select(AccountSpecificSelectors.getAccountSpecificFilters);
  public currency$ = this.store.select(OrgConfigSelectors.getOrgCurrencySetting);
  public isHubspotIngestEnabled$ = this.store.select(UserSelectors.getIsAutomatedHubspotIngest);
  public isShowExternalLink$ = this.store.select(UserSelectors.getIsHubspotOrSalesforce);

  public readonly infoSections = ACCOUNT_INFO_DATA;
  public readonly tabs = ACCOUNT_TABS;
  public readonly portalTargets = PortalTargets;
  private readonly destroy$ = new Subject();


  constructor(
    private store: Store<AccountSpecificState>,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private winowService: TsWindowService,
  ) {
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => this.store.dispatch(accountSpecificActions.setRouteData()));
  }

  applyNewQueryParams(command?: string): void {
    this.router.navigate(command ? [command] : [], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).then();
  }

  handleSelectedTabChange(link: TsTabChangeEvent) {
    this.applyNewQueryParams(this.tabs[link.index].link);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirect(url: string): void {
    this.winowService.nativeWindow.open(url, '_blank');
  }
}
