<div [tsLoadingOverlay]="(isLoading$ | async) > 0">
  <div class="header-container">
    <div class="account-info" *ngIf="accountInfo$ | async as accountInfo">
      <div class="account-info-section"
        *ngFor="let infoSection of infoSections">
        <strong>{{ 'dataStudio.features.accountSpecific.' + infoSection.name | translate }}</strong>
        <span *ngIf="!infoSection.isLink else external">{{ accountInfo[infoSection.name] | typedData : infoSection.dataType : infoSection.decimal || 0 : false : currency }}</span>
        <ng-template #external>
          <ts-link [destination]="accountInfo[infoSection.name]" [isExternal]="true">
            {{ accountInfo[infoSection.name] | typedData : infoSection.dataType }}
          </ts-link>
        </ng-template>
      </div>
    </div>
  </div>
  <ts-tab-collection
    (selectedTabChange)="handleSelectedTabChange($event)"
    [selectedIndex]="selectedTab$ | async">
    <ts-tab *ngFor="let tab of tabs" [label]="'dataStudio.features.accountSpecific.tabs.' + tab.name | translate" data-cy="ds-account-specific-tab">
    </ts-tab>
  </ts-tab-collection>
  <router-outlet></router-outlet>

  <ng-template [tshAttachTo]="portalTargets.HeaderButtons">
    <div class="buttons" *ngIf="isShowExternalLink$ | async">
      <ts-button
        *ngIf="((crmUrl$ | async) + '/' + (filters$ | async).accountId) as url"
        [title]="url"
        theme="secondary"
        (click)="redirect(url)">
        <b *ngIf="(isHubspotIngestEnabled$ | async)">{{ 'dataStudio.features.accountSpecific.viewInHubspot' | translate }} <i class="fas fa-external-link"></i></b>
        <b *ngIf="(isHubspotIngestEnabled$ | async) === false">{{ 'dataStudio.features.accountSpecific.viewInSalesforce' | translate }} <i class="fas fa-external-link"></i></b>
      </ts-button>
    </div>
  </ng-template>
</div>

